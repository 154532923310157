<template>
  <Card
    class="sm:col-span-2 col-span-full"
  >
    <template #title>
      <h2>Donations</h2>
    </template>
    <template #content>
      <div class="flex space-x-6">
        <div class="flex flex-col items-center">
          <p class="text-4xl font-semibold text-center">
            {{ formatAmount((donationStats?.total_amount || 0) / 100) }}
          </p>
          <p class="text-sm text-center">
            {{ $t("components.donations.donation-card.total-amount") }}
          </p>
        </div>
      </div>
      <Divider />
      <DataView
        :value="donationsData?.data"
        data-key="id"
        :loading="loading"
        :pt="{
          content: 'space-y-2 bg-transparent mb-2',
        }"
        lazy
        paginator
        :rows="donationsData?.per_page"
        :total-records="donationsData?.total"
        :always-show-paginator="false"
        @page="onPage"
      >
        <template #empty>
          <div class="flex flex-col items-center justify-center h-96">
            <p class="text-lg font-semibold">
              {{ $t("pages.users.donations.index.no-donations") }}
            </p>
          </div>
        </template>
        <template #list="slotProps">
          <DonationItem
            v-for="data in slotProps.items"
            :key="data.id"
            class="flex justify-between p-4 bg-white rounded-md"
            :donation="data"
          />
        </template>
      </DataView>
    </template>
    <template #footer>
      <Button
        :label="$t('actions.add-donation')"
        @click="createDonationForUserModalVisible = true"
      />
    </template>
  </Card>
  <CreateDonationForUserModal
    v-model="createDonationForUserModalVisible"
    class="w-full max-w-xl mx-4 sm:mx-0"
    :user="user"
    @donation-created="retrieveDonations();retrieveDonationStats();"
  />
</template>

<script lang="ts" setup>
import { formatAmount } from "@/services/payments";
import { User } from "@/stores/authStore";
import Button from "primevue/button";
import Card from "primevue/card";
import DataView, { DataViewPageEvent } from "primevue/dataview";
import Divider from "primevue/divider";
import {
  Donation,
  DonationStats,
  getDonationStatsOfUserOnMosque,
  getDonationsOfMosqueUser
} from "@/services/donations";
import { PaginatedResponse } from "@/composables/api";
import { ref } from "vue";
import { onMounted } from "vue";
import { watch } from "vue";
import useMosqueStore from "@/stores/mosqueStore";
import { storeToRefs } from "pinia";
import CreateDonationForUserModal from "@/components/Modals/Donations/CreateDonationForUserModal.vue";
import DonationItem from "@/components/Donations/DonationItem.vue";

const createDonationForUserModalVisible = ref(false);

const loading = ref(true);

const donationsData = ref<PaginatedResponse<Donation>>();

const { selectedMosque } = storeToRefs(useMosqueStore());

const retrieveDonations = async () => {
  if (!selectedMosque.value || !user) {
    throw new Error("Mosque or user not set");
  };

  loading.value = true;
  donationsData.value = await getDonationsOfMosqueUser(
    selectedMosque.value?.id,
    user.id,
    {
      include: "latestPayment",
    },
    {
      page: page.value,
      per_page: 5,
    }
  );
  loading.value = false;
};

const donationStats = ref<DonationStats>()

const retrieveDonationStats = async () => {
  if (!selectedMosque.value) {
    throw new Error("Mosque not set");
  };
  donationStats.value = await getDonationStatsOfUserOnMosque(
    selectedMosque.value.id,
    user.id
  );
};

const page = ref(1);

const onPage = (event: DataViewPageEvent) => {
  page.value = event.page + 1;
};

watch(page, () => {
  retrieveDonations();
});

onMounted(() => {
  retrieveDonations();
  retrieveDonationStats();
});

const { user } = defineProps<{
  user: User;
}>();
</script>

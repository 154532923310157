import { useI18n } from "vue-i18n"

export default class Enum {
    static [Symbol.iterator] (): IterableIterator<string> {
        return this.values.values()
      }

    static get translationKey (): string {
        return ''
    }
      
    static get keys(): string[] {
        return Object.keys(this)
    }

    static get values(): string[] {
        return Object.values(this)
    }

    static getKey(value: string): string {
        return this.keys[this.values.indexOf(value)]
    }

    static getValue(key: string): string {
        return this.keys[this.values.indexOf(key)]
    }

    static get objects (): {key: string, value: string}[] {
        return this.keys.map((key, index) => {
            return {
                key,
                value: this.values[index],
                label: this.getTranslatedValue(this.values[index])
            }
        })
    }

    static getTranslatedValue (value: string): string {
        const {t} = useI18n()

        return t(`enums.${this.translationKey}.${value}`)
    }
}
import useApi from "@/composables/api";

const requestPasswordReset = async (email: string) => {
    const { client } = useApi();

    await client.post('auth/password/send', { email });
}

const resetPassword = async (data: { token: string; email: string; password: string; }) => {
    const { client } = useApi();

    await client.post('auth/password/reset', data);
}

export {
    requestPasswordReset,
    resetPassword
}

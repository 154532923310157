<template>
  <div class="grid grid-cols-1 gap-4 pt-10 sm:grid-cols-2">
    <div
      v-tooltip.top="{
        value: $t('pages.mosques.edit.clickToChangeBanner'),
        showDelay: 500,
      }"
      class="relative w-full p-4 overflow-hidden bg-white rounded-md cursor-pointer col-span-full"
      @click="bannerModalVisible = true"
    >
      <div class="absolute z-10 flex flex-col items-center justify-center p-4 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <ImageUpload
          v-tooltip="$t('pages.mosques.edit.clickToChangeLogo')"
          :path="`/mosques/${selectedMosque?.id}`"
          method="PATCH"
          :image="getMediaUrl(selectedMosque?.media, 'logo', '320', 'https://placehold.co/320')"
          class="w-24 h-24 border-4 rounded-md cursor-pointer"
          name="logo"
          @upload="retrieveMosquesOfUser"
        />
        <h2 class="text-3xl font-bold text-white">
          {{ selectedMosque?.name }}
        </h2>
      </div>
      <ImageUpload
        :path="`/mosques/${selectedMosque?.id}`"
        method="PATCH"
        class="object-cover w-full h-64 rounded-md"
        :image="getMediaUrl(selectedMosque?.media, 'banner', 'xl', 'https://placehold.co/1200x400')"
        name="banner"
        accept="image/jpg"
        @upload="retrieveMosquesOfUser"
      />
    </div>
    <AddressCard
      :address="selectedMosque?.address"
      class="col-span-1 row-span-2"
      @save="save"
    />
    <ContributionAmountCard class="col-span-1" />
  </div>
</template>

<script lang="ts" setup>
import ContributionAmountCard from "@/components/Mosques/ContributionAmountCard.vue";
import AddressCard from "@/components/Profile/AddressCard.vue";
import ImageUpload from "@/components/form/ImageUpload.vue";
import { Address } from "@/services/addresses";
import { getMediaUrl } from "@/services/media";
import { updateMosque } from "@/services/mosques";
import useMosqueStore from "@/stores/mosqueStore";
import { pick } from "lodash";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const bannerModalVisible = ref(false);

const { selectedMosque } = storeToRefs(useMosqueStore());
const { retrieveMosquesOfUser } = useMosqueStore()

const save = async (address: Address, onSuccess: () => void, onError: (error: unknown) => void) => {
  try {
    if (!selectedMosque.value) return;
    const newMosque = await updateMosque(selectedMosque.value.id, {
      address: pick(address, [
        "street_name",
        "house_number",
        "city",
        "postal_code",
        "country_code",
      ]),
    });

    selectedMosque.value.address = newMosque.address;

    onSuccess();
  } catch (error) {
    onError(error);
  }
};

</script>

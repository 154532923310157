<template>
  <h1 class="text-4xl font-bold text-center text-sea-green-500">
    {{ $t("pages.auth.forgot-password.title") }}
  </h1>
  <form
    class="flex flex-col w-full max-w-md space-y-2"
    @submit.prevent="requestReset"
  >
    <Message
      v-if="message"
      severity="error"
      @close="clearMessage"
    >
      {{ message }}
    </Message>
    <TextInput
      v-model="email"
      type="email"
      :label="$t('email')"
      :placeholder="$t('pages.auth.login.form.email.placeholder')"
      :errors="getErrors('email')"
    />
    <Button
      :loading="loading"
      type="submit"
      :label="$t('pages.auth.forgot-password.request-reset')"
    />
    <RouterLink
      to="/auth/login"
      class="text-sm text-sea-green-400"
    >
      <Button
        type="submit"
        :label="$t('pages.auth.forgot-password.back-to-login')"
        class="w-full"
        link
      />
    </RouterLink>
  </form>
</template>

<script lang="ts" setup>
import Button from "primevue/button";
import Message from "primevue/message";
import TextInput from "@/components/form/TextInput.vue";
import useError from "@/composables/error";
import { ref } from "vue";
import { isAxiosError } from "axios";
import { requestPasswordReset } from "@/services/auth";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

const loading = ref(false)

const { getErrors, message, setErrorBag, clearMessage } = useError()

const email = ref('')

const {add} = useToast()
const {t} = useI18n()

const requestReset = async () => {
  loading.value = true;
  try {
    await requestPasswordReset(email.value)
    loading.value = false

    add({
      severity: "success",
      detail: t('pages.auth.forgot-password.successMessage'),
    });
  } catch (error) {
    if(isAxiosError(error)) {
      setErrorBag(error.response?.data)
    }
    loading.value = false
  }

}
</script>

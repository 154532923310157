<template>
  <div class="flex flex-col">
    <label
      v-if="label"
      for="email"
    >
      {{ label }}
      <i
        v-if="info"
        v-tooltip="info"
        class="text-xs pi pi-info-circle"
      />
    </label>
    <slot
      v-bind="attrs"
      :class="{
        'p-invalid': errors,
      }"
    />
    <slot name="help" />
    <ul v-if="errors">
      <li
        v-for="(error, index) in errors"
        :key="index"
      >
        <small
          class="text-red-500"
        >
          {{ error }}
        </small>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useAttrs } from 'vue';

const attrs = useAttrs()

defineProps<{
  errors?: Array<string> | undefined;
  label?: string | undefined;
  info?: string | undefined;
  placeholder?: string | undefined;
  horizontal?: boolean | undefined;
}>();
</script>

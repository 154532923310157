<template>
  <div class="bg-sea-green-50">
    <Header
      v-if="isAuthenticated"
      class="container px-4 pt-10 mx-auto"
    />
    <div class="w-full h-screen px-4 pt-10 space-y-4 sm:px-0">
      <div
        v-if="mosque"
        class="max-w-md p-4 mx-auto bg-white rounded-md"
      >
        <div class="flex flex-col space-y-8">
          <div class="flex flex-col items-center">
            <img
              :src="getMediaUrl(mosque?.media, 'logo', '160')"
              class="w-20 h-20 rounded-md"
            >
            <h2 class="text-3xl text-center text-sea-green-400">
              {{ mosque?.name }}
            </h2>
          </div>
          <form
            class="w-full space-y-4"
            @submit.prevent="submit"
          >
            <InputWrapper
              :errors="error.getErrors('amount')"
              :label="$t('pages.donations.create.form.amount')"
            >
              <InputNumber
                v-model="form.amount"
                mode="currency"
                currency="EUR"
                locale="nl-Nl"
                class="w-full"
                :pt="{
                  input: 'w-20'
                }"
              />
            </InputWrapper>
            <InputWrapper
              :errors="error.getErrors('note')"
              :label="$t('pages.donations.create.form.note')"
            >
              <Textarea
                v-model="form.note"
                :placeholder="$t('pages.donations.create.form.note')"
                class="w-full"
              />
            </InputWrapper>
            <InputWrapper
              :errors="error.getErrors('anonymous')"
              :info="
                !isAuthenticated
                  ? $t('pages.donations.create.info-anonymous-toggle')
                  : undefined
              "
              :label="$t('pages.donations.create.form.anonymous')"
            >
              <InputSwitch
                v-model="form.anonymous"
                :disabled="!isAuthenticated"
              />
            </InputWrapper>
            <Button
              type="submit"
              class="w-full"
              :label="$t('pages.donations.create.form.donate')"
            />
          </form>
        </div>
      </div>
      <div v-else>
        <div class="max-w-md p-4 mx-auto bg-white rounded-md">
          <h2 class="text-3xl text-center text-sea-green-400">
            {{ $t("pages.donations.create.mosque-not-found") }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { getMosque } from "@/services/mosques";
import { onMounted } from "vue";
import { isAxiosError } from "axios";
import { getMediaUrl } from "@/services/media";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import InputWrapper from "@/components/form/InputWrapper.vue";
import InputSwitch from "primevue/inputswitch";
import useAuthStore from "@/stores/authStore";
import { storeToRefs } from "pinia";
import { Donation, createDonation } from "@/services/donations";
import { useToast } from "primevue/usetoast";
import usePaymentStore from "@/stores/paymentStore";
import Textarea from "primevue/textarea";
import Header from "@/components/Header.vue";
import useForm from "@/composables/form";

const { isAuthenticated } = storeToRefs(useAuthStore());

const { params } = useRoute();
const mosqueId = params.mosqueId as string;

const { resolve } = useRouter();
const { add } = useToast();
const { setCurrentPayment, setContinuePath } = usePaymentStore();
const { path } = useRoute();

const { form, submit, error} = useForm<{
  amount: number;
  note: string;
  redirect_url: string;
  anonymous: boolean;
}, Donation>(
  {
    amount: 0,
    note: "",
    redirect_url: `${window.location.origin}${resolve({ name: "PaymentsValidate" }).href}`,
    anonymous: false,
  },
  async (form) => {
    return await createDonation(
      mosqueId,
      {
        amount: form.amount * 100,
        note: form.note,
        redirect_url: form.redirect_url,
        anonymous: form.anonymous,
      }
    );
  },
  (donation) => {
    if (donation.open_payment) setCurrentPayment(donation.open_payment);
    setContinuePath(
      isAuthenticated.value ? resolve({ name: "Dashboard" }).href : path
    );

    // redirect to payment page
    if (donation.open_payment?.checkout_url) {
      location.href = donation.open_payment?.checkout_url;
    }
  },
  (e) => {
    if (isAxiosError(e)){
      add({
        severity: "error",
        summary: "Error",
        detail: e.response?.data.message,
      })
    }
  }
);

onMounted(() => {
  if (!isAuthenticated.value) {
    form.value.anonymous = true;
  }
});

const { data: mosque } = getMosque(mosqueId, {
  include: 'media',
});
</script>

<template>
  <div class="pt-20 space-y-4">
    <Toolbar class="col-span-full">
      <template #end>
        <Button
          :label="$t('pages.mosques.edit.inviteMembers')"
          @click="createInvitationsModalVisible = true"
        />
        <CreateInvitationsModal
          v-model="createInvitationsModalVisible"
          class="w-full max-w-2xl mx-4 sm:mx-0"
        />
      </template>
    </Toolbar>
    <DataTable
      :value="invitations"
      lazy
      paginator
      :rows="invitationsData?.per_page"
      :total-records="invitationsData?.total"
      :loading="loading"
      @page="onPage"
    >
      <template #empty>
        {{ $t('pages.mosques.users.index.noUsersFound') }}
      </template>
      <template #loading>
        {{ $t('pages.mosques.users.index.loadingUsers') }}
      </template>
      <Column
        field="name"
        :header="$t('mosques.name')"
        style="min-width: 12rem"
      >
        <template #body="{ data }">
          <router-link
            class="hover:underline"
            :to="{
              name: 'MosquesUsersShow',
              params: {
                userId: data.user.id,
              },
            }"
          >
            {{ data.user.first_name }} {{ data.user.last_name }}
          </router-link>
        </template>
      </Column>
      <Column
        field="memberSince"
        :header="$t('pages.mosques.users.index.memberSince')"
        style="min-width: 12rem"
      >
        <template #body="{ data }">
          <p>{{ format(data.accepted_at, 'dd-MM-y') }}</p>
        </template>
      </Column>
      <Column
        field="role"
        :header="$t('invitations.roles')"
        style="min-width: 12rem"
      >
        <template #body="{ data }">
          <div class="flex">
            <Tag
              v-for="(role, index) in data.roles"
              :key="index"
            >
              {{ Role.getTranslatedValue(role.name) }}
            </Tag>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
  
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import DataTable, { DataTablePageEvent } from 'primevue/datatable';
import Column from 'primevue/column';
import { getInvitationsOfMosque } from '@/services/mosques';
import {PaginatedResponse} from '@/composables/api';
import useMosqueStore from '@/stores/mosqueStore';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import Tag from 'primevue/tag';
import { Invitation } from '@/services/invitations';
import Role from '@/enums/roles';
import { format } from 'date-fns';
import Toolbar from 'primevue/toolbar';
import CreateInvitationsModal from "@/components/Modals/CreateInvitationsModal.vue";
import Button from "primevue/button";

const createInvitationsModalVisible = ref(false)
const loading = ref(true);

const invitationsData = ref<PaginatedResponse<Invitation>>();

const {selectedMosque} = storeToRefs(useMosqueStore());

const invitations = computed((): Array<Invitation> => invitationsData.value?.data ?? []);
const retrieveInvitations = async () => {
    if(!selectedMosque.value) return;
    loading.value = true;
    invitationsData.value = await getInvitationsOfMosque(selectedMosque.value.id, {
      page: page.value,
      }, "user,roles", {
      status: "accepted",
    });
    loading.value = false;
}

const page = ref(1);

onMounted(() => {
    retrieveInvitations();
});

const onPage = (event: DataTablePageEvent) => {
page.value = event.page + 1;
};

watch(page, () => {
    retrieveInvitations();
});

</script>
import useApi from "@/composables/api";

const getPayment = async (id: number): Promise<Payment> => {
  const { client } = useApi();

  const { data } = await client.get(`/payments/${id}`);

  return data;
}

const formatAmount = (amount: number): string => {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
  }).format(amount);
}

export {
  getPayment,
  formatAmount
}

export interface Payment {
  id: number;
  status: PaymentStatus;
  amount: number;
  user_id?: number | null;
  checkout_url?: string;
  payment_type: PaymentType;
}

export type PaymentType = "psp" | "cash";

export type PaymentStatus = 'open' | 'paid' | 'failed' | 'canceled' | 'expired';


import { defaultsDeep } from 'lodash';
import { DynamicDialogInstance, DynamicDialogOptions } from 'primevue/dynamicdialogoptions';
import { useDialog as useDialogBase } from 'primevue/usedialog';
import { Ref, inject } from 'vue';


export default function useDialog() {
    const { open: openBase } = useDialogBase();

    const open = <T>(content: unknown, options?: Options<T>): DynamicDialogInstance => {
        return openBase(content, defaultsDeep(options, {
            props: {
                modal: true,
                draggable: false,
            },
        } as DynamicDialogOptions));
    }

    const getProps = <T>(): T => {
        const dialog = inject<Ref<DynamicDialogInstance>>('dialogRef');

        return dialog?.value.data as T;
    }


    const close = (dialog: DynamicDialogInstance) => {
        dialog.close();
    }

    const getDialog = (): Ref<DynamicDialogInstance> | undefined => {
        const dialog = inject<Ref<DynamicDialogInstance>>('dialogRef');

        return dialog;
    }

    return {
        open,
        getProps,
        close,
        getDialog
    }
}

export interface Options<T> extends DynamicDialogOptions {
    data?: T;
}
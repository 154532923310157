<template>
  <div class="flex w-full h-screen px-4 lg:px-0">
    <div
      class="flex-col items-center justify-end hidden w-full h-full py-4 bg-sea-green-500 lg:flex"
    >
      <p class="text-xs text-white">
        {{ $t("pages.auth.createdBy") }}
        <a
          class="underline hover:no-underline"
          href="https://bardan.nl"
        >Bardan Agency</a>
      </p>
    </div>
    <div
      class="flex flex-col items-center justify-center w-full space-y-10 lg:px-4"
    >
      <RouterView />
    </div>
  </div>
</template>

<template>
  <Dropdown
    :options="availableLocales"
    :model-value="currentLocale"
    option-value="locale"
    @change="setCurrentLocale($event.value)"
  >
    <template #option="item">
      <span :class="`fi fi-${item.option.icon}`" />
      <span class="ml-2">{{ item.option.name }}</span>
    </template>
    <template #value="item">
      <span :class="`fi fi-${getLocale(item.value)?.icon}`" />
      <span class="ml-2">{{ getLocale(item.value)?.name }}</span>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import "/node_modules/flag-icons/css/flag-icons.min.css";
import useLocaleStore from '@/stores/localeStore';
import Dropdown from 'primevue/dropdown';
import { toRef } from 'vue';

const currentLocale = toRef(useLocaleStore(), 'currentLocale');

const getLocale = (locale?: string) => {
  return availableLocales.find((l) => l.locale === locale);
};

const {availableLocales, setCurrentLocale} = useLocaleStore();
</script>

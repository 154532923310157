import useApi from "@/composables/api";
import { Payment } from "./payments";

const getContributionsOverview = async (mosqueId: string, userId: string, start: string, end: string): Promise<Array<ContributionsOverviewItem>> => {
  const { client } = useApi();

  const { data } = await client.get(
    `/mosques/${mosqueId}/users/${userId}/contributions/overview`,
    {
      params: {
        start_date: start,
        end_date: end,
        include: "payments,openPayment"
      },
    }
  );
  return data;
}

const createContribution = async (mosqueId: string, yearMonths: Array<string>, redirectUrl: string, userId?: string, paymentType?: string) => {
  const { client } = useApi();

  const { data } = await client.post(`/mosques/${mosqueId}/contributions`, {
    year_months: yearMonths,
    redirect_url: redirectUrl,
    user_id: userId,
    payment_type: paymentType
  });

  return data;
}

const updateContributionFrequency = async (mosqueId: string, frequency: ContributionFrequency): Promise<UserPaymentProvider> => {
  const { client } = useApi();

  const { data } = await client.patch(`/mosques/${mosqueId}/contributions/frequency`, {
    frequency
  });

  return data;
}

const getContributionFrequency = async (mosqueId: string): Promise<UserPaymentProvider> => {
  const { client } = useApi();

  const { data } = await client.get(`/mosques/${mosqueId}/contributions/frequency`);

  return data;
}

export {
  getContributionsOverview,
  createContribution,
  updateContributionFrequency,
  getContributionFrequency
}


export interface ContributionsOverviewItem {
  date: string;
  status: string | null;
  contribution_id: number | null;
  contribution_amount: number | null;
  contribution: Contribution;
  open_payment_id: number | null;
}

export interface Contribution {
  paid_payment?: Payment;
  year_months: Array<string>;
  created_at: string;
}

export type ContributionFrequency = "monthly" | "quarterly" | "yearly" | null;

export interface UserPaymentProvider {
  contribution_frequency: ContributionFrequency;
}

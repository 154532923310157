<template>
  <Message
    v-if="!user?.email_verified_at"
    class="col-span-full"
    severity="info"
    :closable="false"
  >
    {{ $t("components.banners.emailNotVerified.message") }}
    <Button
      :label="$t('components.banners.emailNotVerified.action')"
      severity="info"
      class="p-0"
      :loading="loading"
      text
      @click="sendVerificationMail"
    />
  </Message>
</template>

<script lang="ts" setup>
import useError from "@/composables/error";
import { sendVerificationEmail } from "@/services/users";
import useAuthStore from "@/stores/authStore";
import { isAxiosError } from "axios";
import { storeToRefs } from "pinia";
import Button from "primevue/button";
import Message from "primevue/message";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { user } = storeToRefs(useAuthStore());

const loading = ref(false);

const { setErrorBag, message } = useError();

const {add} = useToast();

const { t } = useI18n();

const sendVerificationMail = async () => {
    loading.value = true;
    try {
        await sendVerificationEmail();

        add({
            severity: "success",
            detail: t("components.banners.emailNotVerified.emailSent")
        });
        loading.value = false;
    } catch (error) {
        if(isAxiosError(error)) {
            setErrorBag(error.response?.data);

            add({
                severity: "error",
                detail: message.value
            });
        }
        loading.value = false;
    }
};
</script>

import { clone } from "lodash"
import useError from "./error"
import { UnwrapRef, ref } from "vue"
import { isAxiosError } from "axios"
import { useToast } from "primevue/usetoast"

const useForm = <F, R>(formScheme: F, onSubmit: (form: F) => Promise<R>, onSuccess?: (data: R) => void, onError?: (error: unknown) => void) => {
  const error = useError()

  const toast = useToast()

  const originalForm = clone<F>(formScheme)

  const form = ref<F>(formScheme)

  const loading = ref(false)

  const reset = () => {
    form.value = clone(originalForm) as UnwrapRef<F>
    error.clearErrors()
    error.clearMessage()
  }

  const data = ref<R | null>(null)

  const submit = async () => {
    error.clearErrors()
    error.clearMessage()
    loading.value = true
    try {
      data.value = await onSubmit(form.value as F) as UnwrapRef<R>
      loading.value = false
      if (onSuccess) onSuccess(data.value as R)
    } catch (e) {
      if (onError) onError(e)
      if (isAxiosError(e)) {
        error.setErrorBag(e.response?.data)

        if (e.response?.status === 400) {
          toast.add({ severity: 'error', detail: e.response?.data.message })
        }
      }
      loading.value = false
    }
  }


  return {
    error,
    form,
    reset,
    submit,
    loading,
    data
  }
}

export default useForm

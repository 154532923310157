import { ref } from "vue"
import axios, { AxiosError, AxiosRequestConfig, RawAxiosRequestHeaders } from "axios"
import useAuthStore from "@/stores/authStore"
import router from "@/router"
import useLocaleStore from "@/stores/localeStore"
import { storeToRefs } from "pinia"
import { merge } from "lodash"

const useApi = () => {
  const version = ref('v1')

  const headers = ref<RawAxiosRequestHeaders>({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  })

  const { currentLocale } = storeToRefs(useLocaleStore())

  const configuration = ref<AxiosRequestConfig>({
    baseURL: `${import.meta.env.VITE_BASE_URL}/api/${version.value}`,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': currentLocale.value
    }
  })

  const webClient = axios.create(configuration.value)

  webClient.defaults.withCredentials = true;
  webClient.defaults.withXSRFToken = true;
  webClient.defaults.baseURL = import.meta.env.VITE_BASE_URL;

  const client = axios.create(configuration.value)

  client.defaults.withCredentials = true;
  client.defaults.withXSRFToken = true;
  const { logout } = useAuthStore()


  // redirect to login if token is expired
  client.interceptors.response.use(response => response, async (error) => {
    if (!error.response && router.currentRoute.value.name !== 'ServiceUnavailable') {
      router.push({ name: 'ServiceUnavailable' })
    }
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      logout()
      if (router.currentRoute.value.meta.requiresAuth) {
        await router.push({ name: 'Login' })
      }
    }

    return Promise.reject(error)
  })

  const fetch = <T>(url: string, defaultOptions: AxiosRequestConfig = {}) => {
    const loading = ref(false)
    const data = ref<T>()
    const error = ref<AxiosError<ErrorBag>>()

    const fetch = async (options: AxiosRequestConfig = defaultOptions) => {
      loading.value = true

      try {
        const response = await client.get<T>(options.url ?? url, merge(defaultOptions, options))
        data.value = response.data
      } catch (e) {
        if (axios.isAxiosError(e)) {
          error.value = e
        } else {
          throw e
        }
      } finally {
        loading.value = false
      }
    }

    fetch(defaultOptions)

    return {
      loading,
      data,
      error,
      refetch: fetch
    }
  }

  return {
    configuration,
    client,
    headers,
    fetch,
    webClient
  }
}

export default useApi

export interface PaginatedResponse<T> {
  data: T[],
  current_page: number,
  from: number,
  last_page: number,
  last_page_url: string,
  links: Array<{ active: boolean, label: string, url: string }>
  next_page_url: string,
  path: string,
  per_page: number,
  prev_page_url: string,
  to: number
  total: number,
}

export interface ErrorBag {
  message?: string;
  errors?: Record<string, string[]>;
}

export interface PaginationOptions {
  page?: number;
  per_page?: number;
}

export interface QueryOptions {
  include?: string;
  filter?: Record<string, string>;
  sort?: string;
}

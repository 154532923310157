import haversine from 'haversine-distance'

const distanceBetween = (address1: Address, address2: Address) => {
    return haversine(address1, address2)
}

export {
    distanceBetween
}

export interface Address {
    id?: number;
    street_name: string;
    house_number: string;
    city: string;
    state: string;
    postal_code: string;
    country_code: CountryCode | null;
    latitude: number;
    longitude: number;
}

export type CountryCode = 'NL'
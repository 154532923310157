import { createI18n } from 'vue-i18n'
import en from '@/lang/en.json'
import nl from '@/lang/nl.json'
import ar from '@/lang/ar.json'

const i18n = createI18n({
    locale: 'nl',
    legacy: false,
    messages: {
        nl,
        en,
        ar
    }
})

export default i18n
<template>
  <div class="mt-8">
    <DataView
      :value="invitations"
      data-key="id"
      :loading="loading"
      :pt="{
        content: 'space-y-2 bg-transparent',
      }"
    >
      <template #empty>
        <div class="flex flex-col items-center justify-center h-96">
          <p class="text-lg font-semibold">
            {{ $t("pages.invitations.no-invitations") }}
          </p>
        </div>
      </template>
      <template #list="slotProps">
        <div
          v-for="(data, index) in slotProps.items"
          :key="data.id"
          class="flex justify-between p-4 bg-white rounded-md"
        >
          <div class="flex space-x-2">
            <img
              :src="getMediaUrl(data.model.media, 'logo', 160, defaultLogo)"
              :alt="`${data.model.name} logo`"
              class="w-12 rounded-md aspect-square"
            >
            <div class="flex flex-col items-start">
              <div class="flex items-center space-x-2 whitespace-nowrap">
                <p>{{ data.model.name }}</p>
              </div>
              <Tag
                :value="$t(`invitations.status.${data.status}`)"
                :severity="getStatusSeverity(data.status)"
              />
            </div>
          </div>
          <InvitationActions
            v-model:invitation="invitations[index]"
            @delete:invitation="
              invitations.splice(index) && retrieveMosquesOfUser
            "
            @update:invitation="retrieveMosquesOfUser"
          />
        </div>
      </template>
    </DataView>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { getInvitationsForUser, Invitation } from "@/services/invitations";
import useAuthStore from "@/stores/authStore";
import { storeToRefs } from "pinia";
import Tag from "primevue/tag";
import InvitationActions from "@/components/Invitations/InvitationActions.vue";
import useMosqueStore from "@/stores/mosqueStore";
import DataView from "primevue/dataview";
import { getMediaUrl } from "@/services/media";
import defaultLogo from "@/assets/mosques/default-logo.jpg";

const { retrieveMosquesOfUser } = useMosqueStore();

const loading = ref(true);

const { user } = storeToRefs(useAuthStore());

const invitations = ref<Array<Invitation>>([]);
const retrieveInvitations = async () => {
  if (user.value?.id) {
    invitations.value = await getInvitationsForUser(
      user.value?.id,
      {
        include: "model.media",
        filter: {
          status: "pending",
        },
      }
    );
  }
};

onMounted(() => {
  retrieveInvitations();
  loading.value = false;
});

const getStatusSeverity = (status: string) => {
  switch (status) {
    case "pending":
      return "warning";
    case "accepted":
      return "success";
    case "rejected":
      return "danger";
    case "expired":
      return "danger";
    default:
      return "info";
  }
};
</script>

<template>
  <SelectButton
    v-model="form.frequency"
    :pt="{
      root: 'flex',
      button: 'w-1/3'
    }"
    :options="options"
    option-label="label"
    option-value="value"
    :disabled="disabled || loading || loadingCurrentFrequency"
    @change="submit"
  />
</template>

<script lang="ts" setup>
import useForm from '@/composables/form';
import { ContributionFrequency, UserPaymentProvider, updateContributionFrequency, getContributionFrequency } from '@/services/contributions';
import useMosqueStore from '@/stores/mosqueStore';
import SelectButton from 'primevue/selectbutton';
import { useToast } from 'primevue/usetoast';
import { watch } from 'vue';
import { onMounted } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const options: { label: string; value: ContributionFrequency }[] = [
  { label: 'Kwartaal', value: 'quarterly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];

const { selectedMosque } = useMosqueStore();
const { add } = useToast();
const { t } = useI18n();

const { submit, form, loading } = useForm<{
  frequency: ContributionFrequency;
}, UserPaymentProvider>({
  frequency: null,
}, async (form) => {
  if(!selectedMosque) {
    throw new Error('No mosque selected');
  }

  return await updateContributionFrequency(selectedMosque?.id, form.frequency);
}, (data) => {
  add({
    severity: 'success',
    summary: data.contribution_frequency 
      ? t(`components.modals.contributions.contributionFrequencySelect.success.${data.contribution_frequency}`)
      : t(`components.modals.contributions.contributionFrequencySelect.success.disabled`),
  });
}, () => {
  add({
    severity: 'error',
    summary: t('errors.generic'),
  });
});

const loadingCurrentFrequency = ref(true);

const contributionFrequency = ref<UserPaymentProvider | null>(null);

watch(contributionFrequency, (value) => {
  form.value.frequency = value?.contribution_frequency || null;
});

onMounted(async () => {
  if(!selectedMosque) {
    throw new Error('No mosque selected');
  }

  try {
    contributionFrequency.value = await getContributionFrequency(selectedMosque?.id);
    loadingCurrentFrequency.value = false;
  } catch (error) {
    
    loadingCurrentFrequency.value = false;
  }
})

defineProps<{
  disabled: boolean;
}>();
</script>
<template>
  <Toast />
  <div>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>
<script setup lang="ts">
import useAuthStore from "./stores/authStore";
import Toast from "primevue/toast";
import useLocaleStore from "@/stores/localeStore";
import { onMounted } from "vue";

const { setUserFromServer } = useAuthStore();

const {initLocale} = useLocaleStore();

onMounted(initLocale);
onMounted(setUserFromServer);
</script>

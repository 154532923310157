<template>
  <Error
    :error-code="404"
    :title="$t('pages.notFound.title')"
    :subtitle="$t('pages.notFound.subtitle')"
  >
    <template #actions>
      <Button
        icon="pi pi-arrow-left"
        :label="$t('actions.goBack')"
        @click="$router.back()"
      />
      <RouterLink
        v-if="isAuthenticated"
        to="/"
      >
        <Button :label="$t('actions.home')" />
      </RouterLink>
      <RouterLink
        v-if="!isAuthenticated"
        to="/auth/login"
      >
        <Button :label="$t('actions.login')" />
      </RouterLink>
    </template>
  </Error>
</template>

<script lang="ts" setup>
import useAuthStore from "@/stores/authStore";
import { storeToRefs } from "pinia";
import Button from "primevue/button";
import Error from "@/components/Pages/Error.vue";

const { isAuthenticated } = storeToRefs(useAuthStore());
</script>

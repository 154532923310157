<template>
  <Form 
    :form="form"
    :error="error"
    class="w-full space-y-2"
    @submit.prevent="submit"
  >
    <div class="flex gap-2">
      <TextInput
        v-model="props.user.first_name"
        type="text"
        :label="$t('firstName')"
        disabled
        class="w-full"
      />
      <TextInput
        v-model="props.user.last_name"
        type="text"
        :label="$t('lastname')"
        disabled
        class="w-full"
      />
    </div>

    <InputWrapper
      :label="$t('bank-number')"
      :errors="error.getErrors('bank_number')"
    >
      <InputMask
        v-model="form.bank_number"
        type="text"
        required
        mask="NL99aaaa9999999999"
        :readonly="loading"
        :auto-clear="false"
        @blur="form.bank_number = form.bank_number.toUpperCase()"
      />
      <p
        v-if="mandateDetails"
        class="text-xs"
      >
        {{ $t('components.modals.updateMandateModal.bankaccountSignedOn') }} {{ format(mandateDetails.created_at, "d-M-y") }} {{ $t('at') }} {{ format(mandateDetails.created_at, "H:m") }}
      </p>
    </InputWrapper>

    

    <Button
      :loading="loading"
      type="submit"
      :label="$t('actions.create')"
      class="w-full"
    />

    <Divider />
    <h2>{{ $t('components.modals.updateMandateModal.automaticContributionPayments') }}</h2>
    <ContributionFrequencySelect
      :disabled="!mandateDetails"
      class="w-full"
    />
    <InlineMessage
      v-if="!mandateDetails && !loading"
      severity="info"
      :closable="false"
      class="w-full"
    >
      <p class="text-wrap ">
        {{ $t('components.modals.updateMandateModal.noMandate') }}
      </p>
    </InlineMessage>
  </Form>
</template>
  
<script setup lang="ts">
import { User } from '@/stores/authStore';
import Form from '@/components/form/Form.vue';
import useForm from '@/composables/form';
import { createMandateOnMosque, CreateMandateOnMosqueData, MandateDetails, getMandateOnMosque } from '@/services/mandates';
import useMosqueStore from '@/stores/mosqueStore';
import useDialog from '@/composables/useDialog';
import TextInput from '../form/TextInput.vue';
import InputWrapper from '../form/InputWrapper.vue';
import InputMask from 'primevue/inputmask';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { watch } from 'vue';
import { format } from 'date-fns';
import Divider from 'primevue/divider';
import ContributionFrequencySelect from '@/components/Contributions/ContributionFrequencySelect.vue';
import InlineMessage from 'primevue/inlinemessage';

const { selectedMosque } = useMosqueStore()
const { add } = useToast()
const { t } = useI18n()

const dialog = useDialog().getDialog()

const { form, error, submit, loading } = useForm<CreateMandateOnMosqueData, undefined>({
  bank_number: '',
}, async (form) => {
  if (!selectedMosque?.id) throw new Error('No mosque selected')
  return await createMandateOnMosque(selectedMosque?.id, form)
}, () => {
  if(dialog) {
    dialog.value.close()
  }
  
  add({
    severity: 'success',
    summary: t('components.modals.contributionSettingsModal.success'),
  });
}) 

const props = useDialog().getProps<ModalData>()

const mandateDetails = ref<MandateDetails>()

watch(mandateDetails, async (value) => {
  if(value) {
    form.value.bank_number = value.bank_number
  }
})

onMounted(async () => {
  if (!selectedMosque?.id) throw new Error('No mosque selected')
  loading.value = true
  try {
    mandateDetails.value = await getMandateOnMosque(selectedMosque?.id)
  } catch (error) {}
  loading.value = false
})

export interface ModalData {
  user: User
}
</script>
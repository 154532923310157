<template>
  <Card
    :pt="{
      body: 'flex flex-col justify-between h-full',
    }"
  >
    <template #title>
      {{ $t("components.contributions.pay-contribution.title") }}
    </template>
    <template
      v-if="selectedMosqueContribtionAmount"
      #content
    >
      <div class="flex flex-col items-center">
        <p class="text-4xl font-semibold text-center">
          {{ amount }}
        </p>
        <p class="text-sm text-center">
          {{ format(new Date(), "MMMM") }}
        </p>
      </div>
    </template>
    <template
      v-else
      #content
    >
      <div class="flex flex-col items-center">
        {{
          $t(
            "components.contributions.pay-contribution.no-mosque-contribution-amount"
          )
        }}
      </div>
    </template>
    <template
      v-if="selectedMosqueContribtionAmount"
      #footer
    >
      <div class="flex space-x-2">
        <Button
          :label="$t('actions.pay')"
          icon="pi pi-check"
          class="w-full"
          :loading="loading"
          :disabled="!!contribution?.status"
          @click="payContribution"
        />
        <Button
          icon="pi pi-wrench"
          outlined
          @click="openContributionSettingsModal"
        />
      </div>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import Card from "primevue/card";
import Button from "primevue/button";
import { format } from "date-fns";
import useMosqueStore from "@/stores/mosqueStore";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import {
  getContributionsOverview,
  createContribution,
  ContributionsOverviewItem,
} from "@/services/contributions";
import { ref } from "vue";
import { onMounted } from "vue";
import useAuthStore from "@/stores/authStore";
import { isAxiosError } from "axios";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import usePaymentStore from "@/stores/paymentStore";
import useDialog from '@/composables/useDialog';
import UpdateMandateModal, { ModalData } from "@/components/Modals/UpdateMandateModal.vue";
import { useI18n } from "vue-i18n";

const toast = useToast();

const { selectedMosqueContribtionAmount, selectedMosque } = storeToRefs(
  useMosqueStore()
);

const { user } = storeToRefs(useAuthStore());

const contribution = ref<ContributionsOverviewItem>();

const loading = ref(false);

const setCurrentMonthContribution = async () => {
  loading.value = true;
  const currentYearMonth = format(new Date(), "yyyy-MM");
  if(!selectedMosque.value?.id || !user.value?.id) return;
  const data = await getContributionsOverview(
    selectedMosque.value?.id,
    user.value?.id ?? 0,
    currentYearMonth,
    currentYearMonth
  );

  contribution.value = data[0];
  loading.value = false;
};

onMounted(setCurrentMonthContribution);

const amount = computed(() => {
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format((selectedMosqueContribtionAmount.value?.amount ?? 0) / 100);

  return formatter;
});

const {resolve} = useRouter();
const {setCurrentPayment, setContinuePath} = usePaymentStore();

const payContribution = async () => {
  loading.value = true;
  if (!selectedMosque.value?.id) return;

  try {
    const data = await createContribution(
      selectedMosque.value?.id,
      [format(new Date(), "yyyy-MM")],
      `${window.location.origin}${resolve({ name: "PaymentsValidate" }).href}`,
    );

    // set contribution in store, so we can access it on the check page.
    if (data.open_payment) setCurrentPayment(data.open_payment);
    setContinuePath(resolve({ name: "Dashboard" }).href);

    location.href = `${data.open_payment.checkout_url}`;
  } catch (error) {
    if (isAxiosError(error)) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: error.response?.data.message,
      });
    }
    loading.value = false;
  }
};

const { open } = useDialog()
const { t } = useI18n()

const openContributionSettingsModal = () => {
  if (!user.value) throw new Error('User not found');

  open<ModalData>(UpdateMandateModal, {
    props: {
      header: t('components.modals.updateMandateModal.title'),
    },
    data: {
      user: user.value,
    }
  })
};
</script>

import { pickBy } from "lodash";
import { computed, readonly, ref } from "vue";

const useError = () => {
    const errorBag = ref<ErrorBag>({
        message: null,
        errors: {}
    });

    const clearErrors = (field?: string) => {
        if (field) {
            //filter out the field
            errorBag.value.errors = pickBy(errorBag.value.errors, (_value, key) => key !== field);
            return;
        }

        errorBag.value = {
            errors: {},
        };
    };

    const setErrorBag = (errors: ErrorBag) => {
        errorBag.value = errors;
    }

    const getErrors = (field: string) => {
        return errorBag.value.errors && errorBag.value.errors[field] ? errorBag.value.errors[field] : [];
    }

    const addError = (field: string, message: string) => {
        errorBag.value.errors = {
            ...errorBag.value.errors,
            [field]: [
                ...getErrors(field),
                message
            ]
        }
    }

    const message = readonly(computed(() => errorBag.value.message as string | null));

    const clearMessage = () => {
        errorBag.value.message = null;
    }

    return {
        errorBag,
        clearErrors,
        setErrorBag,
        getErrors,
        addError,
        message,
        clearMessage,
    }

}

export default useError;

export interface ErrorBag {
    message?: string | null;
    errors: Record<string, string[]>;
}

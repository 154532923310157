<template>
  <div
    v-if="invitation.status === 'pending'"
    class="flex space-x-2"
  >
    <Button
      severity="danger"
      size="small"
      :loading="rejecting"
      icon="pi pi-times"
      @click="reject"
    />
    <Button
      size="small"
      :loading="accepting"
      icon="pi pi-check"
      class="flex sm:hidden"
      @click="accept"
    />
    <Button
      size="small"
      :loading="accepting"
      icon="pi pi-check"
      label="accept"
      class="hidden sm:flex"
      @click="accept"
    />
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { Invitation, acceptInvitation, rejectInvitation } from '@/services/invitations';
import Button from 'primevue/button';
import { ref } from 'vue';

const props = defineProps({
    invitation: {
        type: Object as PropType<Invitation>,
        required: true
    }
})

const accepting = ref(false);
const rejecting = ref(false);

const emit = defineEmits(['update:invitation', 'delete:invitation']);

const accept = async () => {
    accepting.value = true;
    try {
        const result = await acceptInvitation(props.invitation.uuid)
        
        emit("update:invitation", {
            ...props.invitation,
            accepted_at: result.accepted_at,
            status: result.status
        });
    } catch (error) {
        accepting.value = false;
    }
    accepting.value = false;
}

const reject = async () => {
    rejecting.value = true;
    await rejectInvitation(props.invitation.uuid)
        
    emit("delete:invitation");
    rejecting.value = false;
}


</script>

<style scoped>
.p-button {
    @apply py-1;
}
</style>
<template>
  <Sidebar
    position="right"
    :pt="{
      content: 'space-y-8',
    }"
  >
    <template #default>
      <div class="flex flex-col justify-between h-full">
        <div class="space-y-8">
          <div class="flex flex-col items-center space-y-4">
            <img
              src="https://avatars.githubusercontent.com/u/25149637?v=4"
              alt="Profile"
              class="w-24 h-24 rounded-full"
            >
            <div class="text-center">
              <p class="text-xl text-sea-green">
                {{ user?.first_name }}
              </p>
              <Tag
                v-for="role in selectedMosqueInvitation?.roles"
                :key="role.id"
                class="capitalize"
                :value="Role.getTranslatedValue(role.name)"
              />
              <p
                v-for="role in user?.roles"
                :key="role.id"
                class="text-xs"
              >
                {{ Role.getTranslatedValue(role.name) }}
              </p>
            </div>
          </div>
          <ul
            role="list"
            class="flex flex-col flex-1 gap-y-7"
          >
            <li>
              <ul
                role="list"
                class="-mx-2 space-y-1"
              >
                <li
                  v-for="item in items"
                  :key="items.indexOf(item)"
                >
                  <RouterLink
                    :to="item.to"
                    class="flex items-center p-2 text-sm font-semibold leading-6 rounded-md group gap-x-3"
                    :class="{
                      'text-white bg-sea-green-500': $route.path === item.to,
                      'text-sea-green-300 hover:text-white hover:bg-sea-green-500':
                        $route.path !== item.to,
                    }"
                    @click="() => $emit('update:visible', false)"
                  >
                    <i :class="item.icon" />
                    {{ item.name }}
                  </RouterLink>
                </li>
              </ul>
            </li>
          </ul>
          <ul v-if="hasRolesOnSelectedMosque(['mosque-admin'])">
            <li>
              <div class="text-xs font-semibold leading-6 text-sea-green-200">
                {{ selectedMosque?.name }}
              </div>
              <ul
                role="list"
                class="mt-2 -mx-2 space-y-1"
              >
                <li
                  v-for="item in mosqueAdminItems"
                  :key="mosqueAdminItems.indexOf(item)"
                >
                  <RouterLink
                    :to="item.to"
                    class="flex items-center p-2 text-sm font-semibold leading-6 rounded-md group gap-x-3"
                    :class="{
                      'text-white bg-sea-green-500': $route.path === item.to,
                      'text-sea-green-300 hover:text-white hover:bg-sea-green-500':
                        $route.path !== item.to,
                    }"
                    @click="() => $emit('update:visible', false)"
                  >
                    <i :class="item.icon" />
                    {{ item.name }}
                  </RouterLink>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <div class="text-xs font-semibold leading-6 text-sea-green-200">
                {{ $t("components.sidebar.your-mosques") }}
              </div>
              <ul
                role="list"
                class="mt-2 -mx-2 space-y-1"
              >
                <li
                  v-for="(mosque, index) in mosques"
                  :key="mosque.id"
                >
                  <div
                    class="flex justify-between p-2 text-sm font-semibold leading-6 rounded-md cursor-pointer group"
                    :class="{
                      'text-white bg-sea-green-500': selectedMosque?.id === mosque.id,
                      'text-sea-green-300 hover:text-white hover:bg-sea-green-500':
                        selectedMosque?.id !== mosque.id,
                    }"
                    @click="updateSelectedMosque(index)"
                  >
                    <div class="flex gap-x-3">
                      <img
                        :src="getMediaUrl(mosque.media, 'logo', '160')"
                        class="items-center justify-center w-6 h-6 border border-white rounded-lg shrink-0"
                      >
                      <span class="truncate">{{ mosque.name }}</span>
                    </div>
                    <RouterLink
                      v-if="selectedMosque?.id === mosque.id && hasRolesOnSelectedMosque(['mosque-admin'])"
                      class="flex items-center justify-center w-6 h-full rounded-md cursor-pointer bg-sea-green-500 aspect-square text-sea-bg-sea-green-500 hover:bg-white hover:text-sea-green-500"
                      to="/mosques/edit"
                      @click="() => $emit('update:visible', false)"
                    >
                      <i class="pi pi-wrench " />
                    </RouterLink>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <LanguageSelector />
      </div>
    </template>
  </Sidebar>
</template>

<script lang="ts" setup>
import Sidebar from "primevue/sidebar";
import Tag from "primevue/tag";
import useMosqueStore from "@/stores/mosqueStore";
import { storeToRefs } from "pinia";
import useAuthStore from "@/stores/authStore";
import { getMediaUrl } from "@/services/media";
import { useI18n } from "vue-i18n";
import Role from "@/enums/roles";
import { useRouter } from "vue-router";
import LanguageSelector from "./LanguageSelector.vue";

const { mosques, selectedMosque, selectedMosqueInvitation } = storeToRefs(
  useMosqueStore()
);

const { selectMosque, hasRolesOnSelectedMosque } = useMosqueStore();

const {go} = useRouter();
const updateSelectedMosque = (index: number) => {
  selectMosque(mosques.value[index]);
  go(0);
};

const { user } = storeToRefs(useAuthStore());

interface SidebarItem {
  name: string;
  icon: string;
  to: string;
  roles?: Array<string>;
  mosqueRoles?: Array<string>;
  children?: Array<SidebarItem>;
}

const {t} = useI18n();

const items: Array<SidebarItem> = [
  {
    name: t('sidebar.home'),
    icon: "pi pi-fw pi-home",
    to: "/",
  },
  {
    name: t('sidebar.mosques'),
    icon: "pi pi-fw pi-building",
    to: "/mosques",
  },
  {
    name: t('sidebar.my-donations'),
    icon: "pi pi-fw pi-money-bill",
    to: "/users/donations",
  },
];

const mosqueAdminItems: Array<SidebarItem> = [
  {
    name: t('sidebar.members'),
    icon: "pi pi-fw pi-users",
    to: "/mosques/users",
    roles: ["mosque-admin"],
  },
];
</script>

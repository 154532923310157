<template>
  <div class="flex items-start justify-between">
    <div class="max-w-60 sm:max-w-none">
      <h1
        v-if="$route.path === '/'"
        class="text-4xl font-bold"
      >
        {{ $t('app.greeting') }}
      </h1>
      

      <div class="flex gap-2">
        <RouterLink
          v-if="$route.path !== '/'"
          to="/"
        >
          <Button
            id="profile"
            icon="pi pi-home"
            severity="primary"
            class="grow"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          />
        </RouterLink>
        
        <div>
          <p class="text-xl font-semibold truncate text-sea-green">
            {{ user?.first_name }}
          </p>
          <p class="text-sea-green">
            {{ selectedMosque?.name }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex space-x-2">
      <Button
        icon="pi pi-align-justify"
        class="h-auto"
        severity="primary"
        outlined
        rounded
        @click="isSidebarVisible = !isSidebarVisible"
      />

      <Button
        id="profile"
        icon="pi pi-user"
        class="h-auto"
        severity="primary"
        outlined
        rounded
        aria-haspopup="true"
        aria-controls="overlay_menu"
        @click="profileDropdown?.toggle($event)"
      />
      <Menu
        id="overlay_menu"
        ref="profileDropdown"
        :model="items"
        :popup="true"
      />
    </div>

    <Sidebar v-model:visible="isSidebarVisible" />
  </div>
</template>

<script lang="ts" setup>
import useAuthStore from "@/stores/authStore";
import { storeToRefs } from "pinia";
import Button from "primevue/button";
import Menu from "primevue/menu";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import Sidebar from "./Sidebar.vue";
import useMosqueStore from "@/stores/mosqueStore";
import { useI18n } from "vue-i18n";

const { user } = storeToRefs(useAuthStore());

const profileDropdown = ref<Menu | null>(null);

const { selectedMosque } = storeToRefs(useMosqueStore());

const { push } = useRouter();

const toast = useToast();

const { t } = useI18n();

const items = ref([
  {
    label: t("sidebar.profile"),
    icon: "pi pi-user",
    command: () => {
      push({ path: "/profile/edit" });
    },
  },
  {
    label: t('sidebar.invitations'),
    icon: "pi pi-list",
    command: () => {
      push({ path: "/invitations" });
    },
  },
  {                   
    label: t("sidebar.logout"),
    icon: "pi pi-sign-out",
    command: async () => {
      await push({ path: "/auth/login" });
      useAuthStore().logout();
      toast.add({
        severity: "info",
        detail: t("events.loggedout"),
        life: 3000,
      });
    },
  },
]);

const isSidebarVisible = ref(false);
</script>

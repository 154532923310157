import useApi from "@/composables/api";
import { User } from "@/stores/authStore";

const getUser = async (id: string | number, include?: string): Promise<User> => {
  const { client } = useApi();

  const { data } = await client.get(`/users/${id}`, {
    params: {
      include
    }
  });

  return data;

}

const updateUser = async (id: string | number, userData: Record<string, unknown>): Promise<User> => {
  const { client } = useApi();

  const { data } = await client.patch(`/users/${id}`, userData);

  return data;
}

const sendVerificationEmail = async () => {
  const { client } = useApi();

  await client.post('email-verification/send');
}

const verifyEmail = async (id: number, hash: string): Promise<User> => {
  const { client } = useApi();

  const { data } = await client.post(`email-verification/verify/${id}/${hash}`);

  return data;
}

export {
  getUser,
  updateUser,
  sendVerificationEmail,
  verifyEmail
}

<template>
  <form @submit.prevent="submit">
    <Message
      v-if="error?.message.value"
      severity="error"
      class="col-span-2"
      @close="error.clearMessage"
    >
      {{ error.message.value }}
    </Message>
    <slot />
  </form>
</template>

<script lang="ts" setup>
import useForm from '@/composables/form';
import Message from 'primevue/message';

const emit = defineEmits(['submit']);

const submit = (event: Event) => {
  emit('submit', event);
};

defineProps<{
  error: ReturnType<typeof useForm>['error'];
  form: Record<string, unknown>;
}>();
</script>
<template>
  <div class="w-full h-screen px-4 pt-20 bg-sea-green-50">
    <div class="flex flex-col max-w-md py-4 mx-auto bg-white rounded-md">
      <Steps
        v-model:activeStep="active"
        :model="items"
      />
      <div class="p-4">
        <router-view v-slot="{ Component }">
          <component
            :is="Component"
            ref="activeComponent"
          />
        </router-view>
      </div>
      <div class="flex justify-between px-4">
        <Button
          label="Vorige"
          text
          :disabled="active === 0"
          @click="previous"
        />
        <Button
          :label="isLast ? 'Voltooien' : 'Volgende'"
          text
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Button from 'primevue/button';
import Steps from 'primevue/steps';
import { onMounted } from 'vue';
import { watch } from 'vue';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Address from './Address.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const {t} = useI18n();

const items = [
  {
    label: t('pages.onboarding.profile'),
    route: '/onboarding/profile'
  },
  {
    label: t('pages.onboarding.adress'),
    route: '/onboarding/address'
  },
  {
    label: t('pages.onboarding.neaby-mosques'),
    route: '/onboarding/mosque'
  },
];

const {path} = useRoute()

const getActive = (): number => {
  const index = items.findIndex((item) => {
    return item.route === path
  }) 
  return index === -1 ? 0 : index
}

const active = ref(getActive());

const activeComponent = ref<typeof Address>();

const previous = () => {
  if(active.value > 0) {
    active.value--;
  }
};

const isLast = computed((): boolean => active.value === items.length - 1);

const next = async () => {
  try {
    await activeComponent.value?.save();

    if(active.value === items.length - 1) {
      push('/');
      return
    }
    if(active.value < items.length - 1) active.value++;
  } catch (error) {
    console.error(error);
  }
};

const {push} = useRouter();

watch(active, (newValue) => {
  push(items[newValue].route);
});

onMounted(() => {
  push(items[active.value].route);
});
</script>

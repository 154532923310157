import { RouteRecordRaw } from "vue-router";
import PaymentsValidate from "@/pages/Payments/Validate.vue";

const routes: RouteRecordRaw[] = [
  {
    name: "PaymentsValidate",
    path: "/payments/validate",
    component: PaymentsValidate
  }
];

export default routes;

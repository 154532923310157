import { defineStore } from "pinia";
import { useLocalStorage, usePreferredLanguages } from "@vueuse/core"
import { useI18n } from "vue-i18n";

export type Locale = 'nl' | 'en' | 'ar';

const preferedLocale = usePreferredLanguages();


const getUserPreferredLocale = () => {
  return preferedLocale.value.find((locale: string) => ['nl', 'en', 'ar'].includes(locale)) as Locale || 'en';
}

const useLocaleStore = defineStore('locale', () => {
  const { locale } = useI18n();

  const availableLocales: { locale: Locale, icon: string, name: string }[] = [
    { locale: 'nl', icon: 'nl', name: 'Nederlands' },
    { locale: 'en', icon: 'gb', name: 'English' },
    { locale: 'ar', icon: 'sa', name: 'العربية' }
  ];

  const currentLocale = useLocalStorage<Locale | null>('locale', getUserPreferredLocale());

  const initLocale = () => {
    if (currentLocale.value) {
      setLocale(currentLocale.value);
      return
    }

    setCurrentLocale(getUserPreferredLocale());
  }

  const setCurrentLocale = (newLocale: Locale) => {
    currentLocale.value = newLocale;
    setLocale(newLocale);
  }

  const setLocale = (newLocale: Locale) => {
    locale.value = newLocale;
  }

  return {
    currentLocale,
    setCurrentLocale,
    initLocale,
    availableLocales
  }
});

export default useLocaleStore;

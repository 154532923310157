<template>
  <Form
    :form="form"
    :error="error"
    class="w-full space-y-2"
    @submit.prevent="submit"
  >
    <MonthSelector
      v-model="form.year_months"
      :disabled-year-months="disabledYearMonths"
      :loading="loadingDisabledYearMonths"
      @year-change="onYearChange"
    />
    <div class="flex items-center">
      <div class="w-1/2">
        <p class="text-xl font-semibold">
          {{ formatAmount(totalAmount / 100) }}
        </p>
      </div>
      <Button
        class="w-1/2"
        :loading="loading"
        type="submit"
        :label="$t('actions.add-contribution')"
        :disabled="form.year_months.length < 1"
      />
    </div>
  </Form>
</template>

<script setup lang="ts">
import { User } from '@/stores/authStore';
import Form from '@/components/form/Form.vue';
import useDialog from '@/composables/useDialog';
import useForm from '@/composables/form';
import { Contribution, createContribution, getContributionsOverview } from '@/services/contributions';
import { Mosque } from '@/stores/mosqueStore';
import { useRouter } from 'vue-router';
import MonthSelector from '@/components/form/MonthSelector/MonthSelector.vue';
import { ref } from 'vue';
import { startOfYear, endOfYear, format, setYear } from "date-fns";
import Button from 'primevue/button';
import { computed } from 'vue';
import { formatAmount } from '@/services/payments';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

const props = useDialog().getProps<ModalData>()
const dialog = useDialog().getDialog()

const { resolve } = useRouter()

const loadingDisabledYearMonths = ref(false)

const totalAmount = computed(() => {
  if (!props.mosque.current_contribution_amount?.amount) return 0
  return form.value.year_months.length * props.mosque.current_contribution_amount.amount
})

const { t } = useI18n()
const {add } = useToast()

const { form, error, submit, loading } = useForm<{
  year_months: string[];
  payment_type: 'cash';
  user_id: string;
}, Contribution>({
  user_id: props.user.id,
  year_months: [],
  payment_type: 'cash',
}, async (form) => {
  return await createContribution(
    props.mosque.id,
    form.year_months,
    `${window.location.origin}${resolve({ name: "PaymentsValidate" }).href}`,
    form.user_id,
    form.payment_type
  )
}, () => {
  add({
    severity: 'success',
    summary: t('components.modals.addContribution.success'),
  })
  dialog?.value.close(true)
})

const disabledYearMonths = ref<string[]>([])
const onYearChange = async (year: number) => {

  loadingDisabledYearMonths.value = true
  const start = format(startOfYear(setYear(new Date(), year)), 'yyyy-MM')
  const end = format(endOfYear(setYear(new Date(), year)), 'yyyy-MM')
  const data = await getContributionsOverview(props.mosque.id, props.user.id, start, end)

  // filter on where the status is paid
  disabledYearMonths.value = data.filter((item) => item.status === 'paid').map((item) => item.contribution.year_months).flat()

  loadingDisabledYearMonths.value = false
}

export interface ModalData {
  user: User,
  mosque: Mosque,
}
</script>

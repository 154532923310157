<template>
  <div class="pt-20">
    <DataTable
      :value="mosques"
      lazy
      paginator
      :rows="mosquesData?.per_page"
      :total-records="mosquesData?.total"
      data-key="id"
      :loading="loading"
      @page="onPage"
    >
      <template #header>
        <div class="flex gap-2">
          <IconField icon-position="left">
            <InputIcon class="pi pi-search" />
            <InputText
              v-model="filters['query']"
              :placeholder="$t('actions.search')"
              class="w-full"
              @keydown.enter="retrieveMosques"
            />
          </IconField>
          <Button
            :label="$t('actions.search')"
            class="w-40 sm:w-auto"
            @click="retrieveMosques"
          />
        </div>
      </template>
      <template #empty>
        {{ $t('pages.mosques.index.noMosquesFound') }}
      </template>
      <template #loading>
        {{ $t('pages.mosques.index.loadingMosques') }}
      </template>
      <Column
        field="name"
        :header="$t('mosques.name')"
        style="min-width: 12rem"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            placeholder="Search"
            @keydown.enter="filterCallback()"
          />
        </template>
        <template #body="{ data }">
          <div class="flex items-center space-x-2">
            <img
              :src="getMediaUrl(data.media, 'logo', 160, defaultLogo)"
              :alt="`${data.name} logo`"
              class="w-8 h-8 rounded-md"
            >
            <p>{{ data.name }}</p>
          </div>
        </template>
      </Column>
      <Column
        field="city"
        :header="$t('addresses.city')"
        style="min-width: 12rem"
      >
        <template #body="{ data }">
          <p>{{ data.address?.city }}</p>
        </template>
      </Column>
      <Column
        field="actions"
        :header="$t('pages.mosques.index.actions')"
        style="min-width: 12rem"
      >
        <template #body="{ data }">
          <span
            v-tooltip="{
              disabled: isMosqueJoinable(data),
              value: isUserInvitedToMosque(data) ? $t('pages.mosques.index.alreadyMemberOfThisMosque') : $t('pages.mosques.index.mosqueIsPrivate')
            }"
          >
            <Button
              size="small"
              :label="$t('pages.mosques.index.join')"
              :disabled="!isMosqueJoinable(data)"
              @click="join(data)"
            />
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import DataTable, { DataTablePageEvent } from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import { getMosques, joinMosque } from '@/services/mosques';
import {PaginatedResponse} from '@/composables/api';
import useMosqueStore, { Mosque } from '@/stores/mosqueStore';
import { computed } from 'vue';
import {getMediaUrl} from '@/services/media';
import defaultLogo from '@/assets/mosques/default-logo.jpg';
import Button from 'primevue/button';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { isAxiosError } from 'axios';
import { useI18n } from 'vue-i18n';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';

const filters = ref({
    query: undefined,
});

const loading = ref(true);

const mosquesData = ref<PaginatedResponse<Mosque>>();

const mosques = computed((): Array<Mosque> => mosquesData.value?.data ?? []);
const retrieveMosques = async () => {
    loading.value = true;
    mosquesData.value = await getMosques({
      page: page.value,
    }, "media,address", {
      query: filters.value.query,
    });
    loading.value = false;
}

const page = ref(1);

onMounted(() => {
    retrieveMosques();
});

const onPage = (event: DataTablePageEvent) => {
  page.value = event.page + 1;
};
const { addMosque } = (useMosqueStore());
const {add} = useToast();
const {t} = useI18n();

const join = async (mosque: Mosque) => {
    try {
      await joinMosque(mosque.id, ['mosque-member']);

      addMosque(mosque);

      add({
        severity: 'success',
        detail: t('pages.mosques.index.successfullyJoinedMosque', {
          mosqueName: mosque.name,
        }),
      });
    } catch (error) {
      if(isAxiosError(error)) {
        add({
          severity: 'error',
          detail: error.response?.data?.message
        });
      }
    }
}

watch(page, () => {
    retrieveMosques();
});

const { mosques: userMosques, } = storeToRefs(useMosqueStore());

const isUserInvitedToMosque = (mosque: Mosque) => {
    return userMosques.value.some((userMosque) => userMosque.id === mosque.id);
}

const isMosqueJoinable = (mosque: Mosque) => {
    return !mosque.private && !isUserInvitedToMosque(mosque);
}

</script>

<style scoped>
.p-button {
    @apply py-1;
}
</style>

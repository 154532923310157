import { RouteRecordRaw } from "vue-router";
import UserDonationsIndex from "@/pages/Users/Donations/Index.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/users",
    children: [
      {
        path: "donations",
        name: "UserDonations",
        component: UserDonationsIndex
      }
    ]
  }
];

export default routes;

import { RouteRecordRaw } from "vue-router";
import DonationsCreate from "@/pages/Donations/Create.vue";

const routes: RouteRecordRaw[] = [
  {
    name: "DonationsCreate",
    path: "/donations/create/:mosqueId",
    component: DonationsCreate
  }
];

export default routes;

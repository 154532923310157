<template>
  <div class="grid grid-cols-1 gap-4 pt-10 sm:grid-cols-2">
    <EmailNotVerified class="col-span-full" />
    <MyDetailsCard class="col-span-1" />
    <AddressCard
      :address="user?.address"
      class="col-span-1"
      @save="saveAddress"
    />
    <SecurityCard class="col-span-1" />
  </div>
</template>

<script lang="ts" setup>
import MyDetailsCard from "@/components/Profile/MyDetailsCard.vue";
import SecurityCard from "@/components/Profile/SecurityCard.vue";
import AddressCard from "@/components/Profile/AddressCard.vue";
import useAuthStore from "@/stores/authStore";
import { storeToRefs } from "pinia";
import { Address } from "@/services/addresses";
import { pick } from "lodash";
import { updateUser } from "@/services/users";
import EmailNotVerified from "@/components/Banners/EmailNotVerified.vue";

const {user} = storeToRefs(useAuthStore());

const saveAddress = async (address: Address, onSuccess: () => void, onError: (error: unknown) => void) => {
  try {
    if (!user.value?.id) return;
    const newAddress = await updateUser(user.value.id, {
      address: pick(address, [
        "street_name",
        "house_number",
        "city",
        "postal_code",
        "country_code",
      ]),
    });

    user.value.address = newAddress.address;

    onSuccess();
  } catch (e) {
    onError(e);
  }
};
</script>

<template>
  <Card>
    <template #title>
      {{ $t('components.mosques.contributionAmountCard.title') }}
    </template>
    <template #content>
      <form @submit.prevent="updateContributionAmount">
        <InputWrapper
          :label="$t('components.mosques.contributionAmountCard.amountPerMonth')"
          :errors="getErrors('contribution_amount')"
        >
          <InputNumber
            v-model="amount"
            mode="currency"
            currency="EUR"
            locale="nl-NL"
            class="w-full"
            :disabled="!!selectedMosque?.current_contribution_amount"
          />
          <template
            #help
          >
            <small v-if="selectedMosque?.current_contribution_amount?.active_from">
              {{ $t('mosqueContributionAmounts.activeFrom') }} {{ format(selectedMosque?.current_contribution_amount?.active_from, 'Y-M-d') }}
            </small>
            <small v-else>
              {{ $t('components.mosques.contributionAmountCard.nowActive') }}
            </small>
          </template>
        </InputWrapper>
      </form>
    </template>
    <template #footer>
      <InlineMessage
        v-if="selectedMosque?.current_contribution_amount"
        severity="info"
        class="w-full"
      >
        {{ $t('components.mosques.contributionAmountCard.contactSupport') }}
      </InlineMessage>
      <Button
        v-else
        :label="$t('actions.save')"
        class="w-full"
        :disabled="!amount"
        @click="updateContributionAmount"
      />
    </template>
  </Card>
</template>

<script lang="ts" setup>
import Card from 'primevue/card';
import InputWrapper from '../form/InputWrapper.vue';
import InputNumber from 'primevue/inputnumber';
import useMosqueStore from '@/stores/mosqueStore';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { format, startOfMonth } from 'date-fns';
import Button from 'primevue/button';
import { updateMosque } from '@/services/mosques';
import { isAxiosError } from 'axios';
import useError from '@/composables/error';
import InlineMessage from 'primevue/inlinemessage';
import { watchDeep } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

const { setErrorBag, getErrors } = useError();
const {add} = useToast();
const {t} = useI18n();

const updateContributionAmount = async () => {
    if(!selectedMosque.value?.id || !amount.value) {
        return;
    }

    try {
        await updateMosque(selectedMosque.value.id, {
          contribution_amount: amount.value * 100,
        });

        selectedMosque.value.current_contribution_amount = {
            amount: amount.value * 100,
            active_from: startOfMonth(new Date()).toUTCString(),
        };

        add({
            severity: 'success',
            detail: t('components.mosques.contributionAmountCard.amountUpdated'),
        })
    } catch (error) {
        if(isAxiosError(error)) {
          setErrorBag(error.response?.data);
        }
    }
}

const { selectedMosque } = storeToRefs(useMosqueStore());

const amount = ref<null|number>(null);

const setAmountFromSelectedMosque = () => {
    if(!selectedMosque.value?.current_contribution_amount?.amount) {
        return;
    };

    amount.value = selectedMosque.value.current_contribution_amount.amount / 100;
};

onMounted(setAmountFromSelectedMosque)

watchDeep(selectedMosque, setAmountFromSelectedMosque);
</script>
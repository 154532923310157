<template>
  <div class="space-y-2">
    <div class="flex items-center justify-between w-full">
      <i
        class="text-xl cursor-pointer pi pi-angle-left hover:text-gray-600"
        @click="year--"
      />
      <div class="text-center">
        <p
          class="cursor-pointer"
        >
          {{ year }}
        </p>
        <p class="text-gray-500">
          {{ selectedYearMonths.length }} selected
        </p>
      </div>
      <i
        class="text-xl cursor-pointer pi pi-angle-right hover:text-gray-600"
        @click="year++"
      />
    </div>
    <div class="grid grid-cols-4 grid-rows-1 gap-1">
      <p
        v-for="quarter in 4"
        :key="quarter"
        class="text-sm cursor-pointer"
      >
        Quarter {{ quarter }}
      </p>
    </div>
    <div class="grid grid-flow-col grid-cols-4 grid-rows-3 gap-1">
      <MonthItem
        v-for="month in 12"
        :key="month"
        :month="month"
        :selected="selectedYearMonths.includes(yearMonth(year, month))"
        :disabled="disabledYearMonths.includes(yearMonth(year, month)) || loading"
        @click="onMonthClick(month)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import MonthItem from './MonthItem.vue';
import { watch } from 'vue';

const yearMonth = (year: number, month: number) => `${year}-${month.toString().padStart(2, '0')}`;

const year = ref(new Date().getFullYear());

const emit = defineEmits<{
  (event: 'yearChange', year: number): void;
}>();

watch(year, () => {
  emit('yearChange', year.value);
}, {
  immediate: true,
});

const selectedYearMonths = defineModel<string[]>({
  required: true,
});


const props = defineProps<{
  disabledYearMonths: string[];
  loading: boolean;
}>();

const onMonthClick = (month: number) => {
  const yearMonth = `${year.value}-${month.toString().padStart(2, '0')}`;

  if(props.disabledYearMonths.includes(yearMonth)) {
    return;
  }

  if (selectedYearMonths.value.includes(yearMonth)) {
    selectedYearMonths.value = selectedYearMonths.value.filter((ym) => ym !== yearMonth);
  } else {
    selectedYearMonths.value = [...selectedYearMonths.value, yearMonth];
  }
};
</script>

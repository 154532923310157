import { RouteRecordRaw } from "vue-router";
import ProfileEdit from "@/pages/Profile/Edit.vue";
import VerifyEmail from "@/pages/Profile/VerifyEmail.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/profile/edit",
        name: "ProfileEdit",
        component: ProfileEdit
    },
    {
        path: "/verify-email",
        name: "VerifyEmail",
        component: VerifyEmail
    }
];

export default routes;
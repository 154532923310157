import useApi, { QueryOptions } from "@/composables/api";
import { User } from "@/stores/authStore";
import { Mosque } from "@/stores/mosqueStore";
import { Permission, Role } from "./roles";

const getInvitationsForUser = async (userId: string, queryOptions?: QueryOptions): Promise<Array<Invitation>> => {
  const { client } = useApi();

  const { data } = await client.get(`/users/${userId}/invitations`, {
    params: queryOptions
  });

  return data;
}

const acceptInvitation = async (invitationUuid: string): Promise<Invitation> => {
  const { client } = useApi();

  const { data } = await client.post(`/invitations/${invitationUuid}/accept`);

  return data;
}

const rejectInvitation = async (invitationUuid: string): Promise<Invitation> => {
  const { client } = useApi();

  const { data } = await client.post(`/invitations/${invitationUuid}/reject`);

  return data;
}

const getAvailableRolesOnModelType = async (modelType: string): Promise<Array<string>> => {
  const { client } = useApi();

  const { data } = await client.get(`invitations/${modelType}/available-roles`);

  return data;
}

const createInvitationsOnMosque = async (mosqueId: string, emails: Array<string>, roles: Array<string>): Promise<Array<Invitation>> => {
  const { client } = useApi();

  const { data } = await client.post(`mosques/${mosqueId}/invitations`, {
    emails,
    roles
  });

  return data;

}

export {
  getInvitationsForUser,
  acceptInvitation,
  rejectInvitation,
  getAvailableRolesOnModelType,
  createInvitationsOnMosque
}

export interface Invitation {
  id: number;
  uuid: string;
  user_id: number;
  user: User;
  model_id: number;
  model_type: InvitationModelType;
  model: Mosque;
  creator: User | null;
  created_by: number | null;
  status: InvitationStatus;
  roles: Array<Role>;
  permissions: Array<Permission>;
  accepted_at: string | null;
  created_at: string;
  updated_at: string;
}

export type InvitationStatus = "pending" | "accepted" | "rejected" | "expired";

export type InvitationModelType = "mosque";


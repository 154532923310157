import { RouteRecordRaw } from "vue-router";
import MosquesEdit from "@/pages/Mosques/Edit.vue";
import MosquesIndex from "@/pages/Mosques/Index.vue";
import MosquesUsersIndex from "@/pages/Mosques/Users/Index.vue";
import MosquesUsersShow from "@/pages/Mosques/Users/Show.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/mosques/edit",
    name: "MosquesEdit",
    component: MosquesEdit,
    meta: {
      selectedMosqueRoles: ["mosque-admin"],
    },
  },
  {
    path: "/mosques",
    name: "Mosques",
    component: MosquesIndex
  },
  {
    path: "/mosques/users",
    name: "MosquesUsers",
    component: MosquesUsersIndex
  },
  {
    path: "/mosques/users/:userId",
    name: "MosquesUsersShow",
    component: MosquesUsersShow
  }
];

export default routes;

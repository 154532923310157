<template>
  <Card>
    <template #title>
      {{
        $t("pages.dashboard.contributions-overview")
      }}
    </template>
    <template #content>
      <ContributionsOverview
        v-if="contributionsOverview"
        :contributions-overview="contributionsOverview"
        :loading="loading"
        @item-click="payContribution"
      />
    </template>
  </Card>
</template>

<script lang="ts" setup>
import useAuthStore from "@/stores/authStore";
import useMosqueStore from "@/stores/mosqueStore";
import { ref } from "vue";
import Card from "primevue/card";
import { format, subYears } from "date-fns";
import { storeToRefs } from "pinia";
import { addMonths } from "date-fns/addMonths";
import { ContributionsOverviewItem, createContribution, getContributionsOverview } from "@/services/contributions";
import { onMounted } from "vue";
import { isAxiosError } from "axios";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import usePaymentStore from "@/stores/paymentStore";
import { getPayment } from "@/services/payments";
import ContributionsOverview from "./ContributionOverview/ContributionsOverview.vue";
const { selectedMosque } = storeToRefs(useMosqueStore());
const { user } = storeToRefs(useAuthStore());
const toast = useToast();

const loading = ref(false);

const { resolve } = useRouter();
const { setCurrentPayment, setContinuePath } = usePaymentStore();

const payContribution = async (contributionMonth: ContributionsOverviewItem) => {
  if (!selectedMosque.value?.id) return;
  if (!contributionMonth.contribution_amount) return;
  if(contributionMonth.status === "paid") return;

  if(contributionMonth.status === "open" && contributionMonth.open_payment_id) {
    openPayment(contributionMonth.open_payment_id);
    return;
  }

  try {
    const data = await createContribution(
      selectedMosque.value?.id,
      [format(new Date(contributionMonth.date), "yyyy-MM")],
      `${window.location.origin}${resolve({ name: "PaymentsValidate" }).href}`,
    );

    if (data.open_payment) setCurrentPayment(data.open_payment);
    setContinuePath(resolve({ name: "Dashboard" }).href);

    location.href = data.open_payment?.checkout_url;
  } catch (error) {
    if(isAxiosError(error)) {
      toast.add({ severity: "error", summary: "Error", detail: error.response?.data.message ?? error.message });
    }
  }
};

const openPayment = async (paymentId: number) => {
  const payment = await getPayment(paymentId);

  if (payment) setCurrentPayment(payment);
  setContinuePath(resolve({ name: "Dashboard" }).href);

  if(!payment.checkout_url) return;
  location.href = payment?.checkout_url;
}

const startDate = ref(format(addMonths(subYears(new Date(), 1), 1), "yyyy-MM"));
const endDate = ref(format(new Date(), "yyyy-MM"));

const contributionsOverview = ref<Array<ContributionsOverviewItem>>([]);

const setContributionsOverview = async () => {
  if(!selectedMosque.value?.id || !user.value?.id) {
    throw new Error("No mosque or user selected");
    return;
  };
  loading.value = true;

  const data = await getContributionsOverview(selectedMosque.value?.id, user.value?.id, startDate.value, endDate.value)

  contributionsOverview.value = data;
  loading.value = false;
}

onMounted(async () => {
  await setContributionsOverview();
});
</script>

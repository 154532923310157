import { RouteRecordRaw } from "vue-router";
import OnboardingIndex from "@/pages/Onboarding/Index.vue";

const routes: RouteRecordRaw[] = [
  {
    name: "OnboardingIndex",
    path: "/onboarding",
    component: OnboardingIndex,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: "OnboardingAddress",
        path: "address",
        component: () => import("@/pages/Onboarding/Address.vue")
      },
      {
        name: "OnboardingProfile",
        path: "profile",
        component: () => import("@/pages/Onboarding/Profile.vue")
      },
      {
        name: "OnboardingMosque",
        path: "mosque",
        component: () => import("@/pages/Onboarding/Mosque.vue")
      }
    ]
  }
];

export default routes;

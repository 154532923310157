import { ref } from "vue";
import useApi from "./api";
import axios from "axios";
import useAuthStore from "@/stores/authStore";

const useLogin = () => {
  const email = ref('');
  const password = ref('');

  const { setUserFromServer } = useAuthStore();

  const error = ref<{
    message: string,
    errors: {
      email: string[],
      password: string[]
      token_name: string[]
    }
  } | null>(null);
  const loading = ref(false);

  const { webClient } = useApi();
  const login = async () => {
    try {
      loading.value = true;

      await webClient.get('/sanctum/csrf-cookie');

      (await webClient.post('/auth/login', {
        email: email.value,
        password: password.value,
      })).data


      loading.value = false;

      setUserFromServer();
    } catch (e) {
      loading.value = false;

      if (axios.isAxiosError(e)) {
        error.value = e.response?.data;
        return;
      }
    }
  };

  return {
    email,
    password,
    error,
    loading,
    login
  }
}

export {
  useLogin
}

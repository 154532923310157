<template>
  <Card>
    <template #title>
      <h2>{{ $t("components.profile.securityCard.title") }}</h2>
    </template>
    <template #content>
      <form
        class="flex flex-col w-full gap-4"
        @submit.prevent="savePassword"
      >
        <input
          type="text"
          name="username"
          autocomplete="username email"
          :value="user?.email"
          class="hidden"
        >
        <PasswordInput
          v-model="password"
          :label="$t('password')"
          :placeholder="$t('password')"
          class="w-full"
          autocomplete="new-password"
          toggle-mask
          required
          :errors="error.errors?.password"
        />
        <PasswordInput
          v-model="passwordRepeat"
          :label="$t('repeatPassword')"
          :placeholder="$t('repeatPassword')"
          :feedback="false"
          autocomplete="new-password"
          toggle-mask
          required
          :errors="error.errors?.passwordRepeat"
        />
        <Button
          type="submit"
          :label="$t('actions.changePassword')"
          :disabled="!password || !passwordRepeat"
        />
      </form>
    </template>
  </Card>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import PasswordInput from "@/components/form/PasswordInput.vue";
import Card from "primevue/card";
import Button from "primevue/button";
import { watch } from "vue";
import { ErrorBag } from "@/composables/api";
import { useI18n } from "vue-i18n";
import useAuthStore from "@/stores/authStore";
import { storeToRefs } from "pinia";
import { updateUser } from "@/services/users";
import { AxiosError, isAxiosError } from "axios";
import { useToast } from "primevue/usetoast";

const { user } = storeToRefs(useAuthStore());

const savePassword = async () => {
  if (!user.value) return;
  try {
    await updateUser(user.value.id, { password: password.value });
    onSuccess();
  } catch (e) {
    if (isAxiosError(e)) onError(e);
  }
};

const { add } = useToast();

const onSuccess = () => {
  add({
    severity: "success",
    summary: "Success",
    detail: t("components.profile.securityCard.passwordUpdated"),
  });
  error.value = { message: "", errors: {} };
  password.value = "";
  passwordRepeat.value = "";
};

const onError = (e: AxiosError) => {
  error.value = e.response?.data as ErrorBag;
  add({
    severity: "error",
    summary: "Error",
    detail: error.value.message,
  });
};

const error = ref<ErrorBag>({
  message: "",
  errors: {},
});

const password = ref("");
const passwordRepeat = ref("");

const { t } = useI18n();

watch([password, passwordRepeat], () => {
  if (password.value !== passwordRepeat.value && error.value.errors) {
    error.value.errors["passwordRepeat"] = [
      t("components.profile.securityCard.passwords-do-not-match"),
    ];
  } else if (error.value.errors) {
    delete error.value.errors["passwordRepeat"];
  }
});
</script>

<template>
  <div class="flex justify-center pt-20">
    <Card class="w-full sm:max-w-xl ">
      <template #content>
        <div class="flex flex-col">
          <div
            v-if="loading"
            class="flex flex-col items-center space-y-4"
          >
            <ProgressSpinner
            
              class="mx-auto"
            />
            <h2 class="text-2xl">
              {{ t('profile.verifyEmail.verifying') }}
            </h2>
          </div>
          <div
            v-else
            class="flex flex-col items-center space-y-4"
          >
            <div
              class="flex items-center justify-center w-20 h-20 rounded-full"
              :class="{
                'bg-green-500': isEmailVerified,
                'bg-red-500': !isEmailVerified,
              }"
            >
              <i class="text-3xl text-white pi pi-envelope" />
            </div>
            <h2 class="text-2xl">
              {{ isEmailVerified ? t('profile.verifyEmail.verified') : t('profile.verifyEmail.notVerified') }}
            </h2>
            <router-link to="/">
              <Button
                :label="t('profile.verifyEmail.goToDashboard')"
              />
            </router-link>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts" setup>
import { verifyEmail } from '@/services/users';
import useAuthStore from '@/stores/authStore';
import { isAxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Card from 'primevue/card';
import ProgressSpinner from 'primevue/progressspinner';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { user } = storeToRefs(useAuthStore())
const { setUserFromServer } = useAuthStore()

const {query} = useRoute()
const hash = query.hash as string | undefined
const id = query.id as string | undefined

const {add} = useToast()
const {t} = useI18n()

const loading = ref(false)

const verify = async () => {
    if(!hash || !id) {
        add({severity: 'error', detail: t('profile.verifyEmail.invalidLink')})
        return 
    }

    if(isEmailVerified.value) {
        add({severity: 'info', detail: t('profile.verifyEmail.alreadyVerified')})
        return 
    }

    loading.value = true

    try {
        const newUser = await verifyEmail(parseInt(id), hash)

        if(user.value) {
          user.value.email_verified_at = newUser.email_verified_at
        }

        loading.value = false
    } catch (error) {
        if(isAxiosError(error)) {
            add({severity: 'error', detail: error.response?.data.message})
        } else {
          throw error
        }
        loading.value = false
    }

}

const isEmailVerified = computed(() => {
    return user.value?.email_verified_at
})

onMounted(async () => {
  loading.value = true
  await setUserFromServer()
  await verify()
  loading.value = false
})
</script>
<template>
  <h1 class="text-4xl font-bold text-center text-sea-green-500">
    {{ $t("pages.auth.reset-password.title") }}
  </h1>
  <form
    class="flex flex-col w-full max-w-md space-y-2"
    @submit.prevent="reset"
  >
    <Message
      v-if="message"
      severity="error"
      @close="clearMessage"
    >
      {{ message }}
    </Message>
    <TextInput
      v-model="email"
      type="email"
      :label="$t('email')"
      :placeholder="$t('pages.auth.login.form.email.placeholder')"
      :errors="getErrors('email')"
      disabled
    />
    <PasswordInput
      v-model="password"
      :label="$t('password')"
      :placeholder="$t('password')"
      class="w-full"
      autocomplete="new-password"
      toggle-mask
      required
      :errors="getErrors('password')"
    />
    <PasswordInput
      v-model="passwordRepeat"
      :label="$t('repeatPassword')"
      :placeholder="$t('repeatPassword')"
      :feedback="false"
      autocomplete="new-password"
      toggle-mask
      required
      :errors="getErrors('passwordRepeat')"
    />
    
    <Button
      :loading="loading"
      type="submit"
      :label="$t('pages.auth.reset-password.reset-password')"
    />
    <RouterLink
      to="/auth/login"
      class="text-sm text-sea-green-400"
    >
      <Button
        type="submit"
        :label="$t('pages.auth.forgot-password.back-to-login')"
        class="w-full"
        link
      />
    </RouterLink>
  </form>
</template>

<script lang="ts" setup>
import Button from "primevue/button";
import Message from "primevue/message";
import TextInput from "@/components/form/TextInput.vue";
import useError from "@/composables/error";
import { onMounted, ref } from "vue";
import { isAxiosError } from "axios";
import { resetPassword } from "@/services/auth";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import PasswordInput from "@/components/form/PasswordInput.vue";
import { useRoute, useRouter } from "vue-router";

const loading = ref(false)

const { getErrors, message, setErrorBag, clearMessage } = useError()

const token = ref('')
const email = ref('')
const password = ref('')
const passwordRepeat = ref('')

const {add} = useToast()
const {t} = useI18n()
const {push} = useRouter()

const reset = async () => {
  loading.value = true;
  try {
    loading.value = false

    await resetPassword({
      email: email.value,
      token: token.value,
      password: password.value,
    })
    
    add({
      severity: "success",
      detail: t('pages.auth.reset-password.successMessage'),
    });

    setTimeout(() => {
      push('/auth/login')
    }, 3000)
  } catch (error) {
    if(isAxiosError(error)) {
      setErrorBag(error.response?.data)
    }
    loading.value = false
  }
}

const {query} = useRoute()

onMounted(() => {
  token.value = query.token as string
  email.value = query.email as string

  if(!token.value || !email.value) {
    add({
      severity: "error",
      detail: t('pages.auth.reset-password.missingTokenOrEmail'),
    });

    push('/auth/forgot-password')
  }
})
</script>

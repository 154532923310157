<template>
  <div>
    <Message
      v-if="mosques.length < 1"
      class="col-span-full"
      severity="info"
      :closable="false"
    >
      {{ $t("pages.dashboard.no-connected-mosques") }}
      <RouterLink
        to="/mosques"
        class="underline hover:no-underline"
      >
        {{ $t("pages.dashboard.search-mosques") }}
      </RouterLink>
    </Message>

    <EmailNotVerified />

    <Message
      v-if="
        selectedMosqueInvitation &&
          (selectedMosqueInvitation?.roles.length ?? 0) < 1
      "
      class="col-span-full"
      severity="info"
      :closable="false"
    >
      {{ $t("pages.dashboard.no-roles-on-invitation") }}
    </Message>

    <div
      v-if="selectedMosque"
      class="grid grid-cols-2 gap-2 mt-10 sm:grid-cols-4 xl:grid-cols-8"
    >
      <ContributionsOverviewCard
        v-if="hasRolesOnSelectedMosque(['mosque-member'])"
        class="row-span-2 col-span-full"
      />
      <PayContribution
        v-if="hasRolesOnSelectedMosque(['mosque-member'])"
        class="col-span-2 row-span-2"
      />
      <DonationCard :today-donations-count="10" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import useMosqueStore from "@/stores/mosqueStore";
import Message from "primevue/message";
import ContributionsOverviewCard from "@/components/Contributions/ContributionsOverviewCard.vue";
import { storeToRefs } from "pinia";
import PayContribution from "@/components/Contributions/PayContribution.vue";
import DonationCard from "@/components/Donations/DonationCard.vue";
import EmailNotVerified from "@/components/Banners/EmailNotVerified.vue";

const { mosques, selectedMosque, selectedMosqueInvitation } = storeToRefs(
  useMosqueStore()
);
const { hasRolesOnSelectedMosque } = useMosqueStore();
</script>

import useApi from "@/composables/api"

const createMandateOnMosque = async (mosqueId: string | number, data: CreateMandateOnMosqueData): Promise<undefined> => {
    const { client } = useApi()

    const response = await client.post(`/mosques/${mosqueId}/mandates`, {
        bank_number: data.bank_number.toUpperCase()
    })

    return response.data
}

const getMandateOnMosque = async (mosqueId: string | number): Promise<MandateDetails> => {
    const { client } = useApi()

    const response = await client.get(`/mosques/${mosqueId}/mandates`)

    return response.data
}

export interface CreateMandateOnMosqueData {
    bank_number: string;
}

export interface MandateDetails {
    id: number;
    bank_number: string;
    created_at: string;
}

export {
    createMandateOnMosque,
    getMandateOnMosque
}
<template>
  <h1 class="text-4xl font-bold text-center text-sea-green-500">
    {{ $t("pages.auth.login.greeting") }}
  </h1>
  <form
    class="flex flex-col w-full max-w-md space-y-2"
    @submit.prevent="login"
  >
    <Message
      v-if="error?.message"
      severity="error"
      @close="error.message = ''"
    >
      {{ error?.message }}
    </Message>
    <TextInput
      v-model="email"
      type="email"
      :label="$t('email')"
      :placeholder="$t('pages.auth.login.form.email.placeholder')"
      :errors="error?.errors?.email"
      autocomplete="username"
      required
    />
    <PasswordInput
      v-model="password"
      :label="$t('password')"
      :placeholder="$t('pages.auth.login.form.password.placeholder')"
      toggle-mask
      :errors="error?.errors?.password"
      required
      :feedback="false"
    />
    <div class="flex justify-end w-full">
      <RouterLink
        to="/auth/forgot-password"
        class="text-sm text-sea-green-400"
      >
        {{ $t("pages.auth.login.forgot-password") }}
      </RouterLink>
    </div>
    <Button
      :loading="loading"
      type="submit"
      :label="$t('pages.auth.login.form.login')"
    />
    <RouterLink
      to="/auth/register"
      class="text-sm text-sea-green-400"
    >
      <Button
        type="submit"
        :label="$t('pages.auth.login.form.register')"
        class="w-full"
        link
      />
    </RouterLink>
    <div
      v-if="development"
      class="flex gap-2"
    >
      <Button
        label="Admin"
        class="w-full"
        @click="loginAs('admin@ibtikar.nl')"
      />
      <Button
        label="Mosque Admin"
        class="w-full"
        @click="loginAs('admin@mosque.test')"
      />
      <Button
        label="Mosque Member"
        class="w-full"
        @click="loginAs('member@mosque.test')"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import Button from "primevue/button";
import Message from "primevue/message";
import { useLogin } from "@/composables/auth";
import useAuthStore from "@/stores/authStore";
import { watch } from "vue";
import TextInput from "@/components/form/TextInput.vue";
import PasswordInput from "@/components/form/PasswordInput.vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";

const { email, password, login, error, loading } = useLogin();

const { push } = useRouter();
const { query } = useRoute();

const {isAuthenticated} = storeToRefs(useAuthStore());

watch(isAuthenticated, () => {
  if (isAuthenticated.value) {
    const redirect = query.redirect?.toString()
      ? query.redirect.toString()
      : "/";

    push(redirect);
  }
});

const development = import.meta.env.DEV;

const loginAs = (userEmail: string) => {
  email.value = userEmail;
  password.value = "password";
  login();
}
</script>

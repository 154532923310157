<template>
  <Dialog
    v-model:visible="model"
    modal
    :draggable="false"
  >
    <template #header>
      <h2 class="font-bold white-space-nowrap">
        {{ $t('components.modals.donations.createDonationForUserModal.title') }}
      </h2>
    </template>
    <Form
      class="flex flex-col w-full gap-2"
      :error="error"
      :form="form"
      @submit.prevent="submit"
    >
      <InputWrapper
        :errors="error.getErrors('amount')"
        :label="$t('pages.donations.create.form.amount')"
      >
        <InputNumber
          v-model="form.amount"
          mode="currency"
          currency="EUR"
          locale="nl-Nl"
          class="w-full"
        />
      </InputWrapper>
      <InputWrapper
        :errors="error.getErrors('note')"
        :label="$t('pages.donations.create.form.note')"
      >
        <Textarea
          v-model="form.note"
          :placeholder="$t('pages.donations.create.form.note')"
          class="w-full"
        />
      </InputWrapper>
      <InputWrapper
        :errors="error.getErrors('payment_type')"
        :label="$t('pages.donations.create.form.payment_type')"
      >
        <Dropdown
          v-model="form.payment_type"
          class="w-full"
          :options="[
            { label: $t('payments.types.cash'), value: 'cash' },
          ]"
          option-label="label"
          option-value="value"
          disabled
        />
      </InputWrapper>
      <InputWrapper
        :errors="error.getErrors('anonymous')"
        :label="$t('pages.donations.create.form.anonymous')"
      >
        <InputSwitch
          v-model="form.anonymous"
        />
      </InputWrapper>
    </Form>
    <template #footer>
      <Button
        outlined
        severity="secondary"
        :label="t('actions.cancel')"
        @click="closeModal"
      />
      <Button
        :label="t('components.modals.donations.createDonationForUserModal.create')"
        :loading="loading"
        @click="submit"
      />
    </template>
  </Dialog>
</template>
  
<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import InputWrapper from '@/components/form/InputWrapper.vue';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import useForm from '@/composables/form';
import { Donation } from '@/services/donations';
import Form from '@/components/form/Form.vue';
import { createDonationOnMosque } from '@/services/donations';
import type { PaymentType } from '@/services/payments';
import useMosqueStore from '@/stores/mosqueStore';
import { useRouter } from 'vue-router';
import { User } from '@/stores/authStore';
import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';

const { selectedMosque } = useMosqueStore();
const {resolve} = useRouter();
const {add} = useToast();
const {t} = useI18n();

const emit = defineEmits(['donation-created']);

const { form, loading, error, submit, reset } = useForm<{
  amount: number;
  note: string | undefined;
  anonymous: boolean;
  payment_type: PaymentType;
}, Donation>({
  amount: 0,
  note: undefined,
  anonymous: false,
  payment_type: 'cash',
}, async (form) => {
  if(!selectedMosque?.id) throw new Error('No mosque selected');

  return await createDonationOnMosque({
    amount: form.amount * 100,
    anonymous: form.anonymous,
    note: form.note,
    mosque_id: selectedMosque.id,
    payment_type: 'cash',
    redirect_url: `${window.location.origin}${resolve({ name: "PaymentsValidate" }).href}`,
    user_id: props.user.id,
  });
}, () => {
  add({
    severity: 'success',
    summary: t('components.modals.donations.createDonationForUserModal.success'),
  });

  emit('donation-created');
  
  closeModal();
  
  reset()
});

const props = defineProps<{
  user: User;
}>();

const closeModal = () => {
  model.value = false;
}

const model = defineModel({
    required: true,
    type: Boolean,
})

watch(model, (value) => {
    if (!value) {
        error.clearErrors();
    }
})
</script>
<template>
  <div class="mt-8 space-y-4">
    <Toolbar>
      <template #end>
        <RouterLink
          v-if="selectedMosque"
          :to="{
            name: 'DonationsCreate',
            params: {
              mosqueId: selectedMosque?.id,
            },
          }"
        >
          <Button :label="$t('pages.users.donations.index.createDonation')" />
        </RouterLink>
      </template>
    </Toolbar>
    <DataView
      :value="donationsData?.data"
      data-key="id"
      :loading="loading"
      :pt="{
        content: 'space-y-2 bg-transparent mb-2',
      }"
      lazy
      paginator
      :rows="donationsData?.per_page"
      :total-records="donationsData?.total"
      @page="onPage"
    >
      <template #empty>
        <div class="flex flex-col items-center justify-center h-96">
          <p class="text-lg font-semibold">
            {{ $t("pages.users.donations.index.no-donations") }}
          </p>
        </div>
      </template>
      <template #list="slotProps">
        <DonationItem
          v-for="data in slotProps.items"
          :key="data.id"
          :donation="data"
          class="flex justify-between p-4 bg-white rounded-md"
          show-mosque
          @click="openPayment(data.latest_payment?.id)"
        />
      </template>
    </DataView>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { getDonationsOfCurrentUser, Donation } from "@/services/donations";
import DataView, { DataViewPageEvent } from "primevue/dataview";
import { PaginatedResponse } from "@/composables/api";
import { getPayment } from "@/services/payments";
import { watch } from "vue";
import { useRouter } from "vue-router";
import usePaymentStore from "@/stores/paymentStore";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import { storeToRefs } from "pinia";
import useMosqueStore from "@/stores/mosqueStore";
import DonationItem from "@/components/Donations/DonationItem.vue";

const { selectedMosque } = storeToRefs(useMosqueStore());

const { resolve } = useRouter();
const { setContinuePath, setCurrentPayment } = usePaymentStore();

const openPayment = async (paymentId: number) => {
  const payment = await getPayment(paymentId);

  if (payment) setCurrentPayment(payment);
  setContinuePath(resolve({ name: "UserDonations" }).href);

  if (!payment.checkout_url) return;
  location.href = payment?.checkout_url;
};

const loading = ref(true);

const donationsData = ref<PaginatedResponse<Donation>>();
const retrieveDonations = async () => {
  donationsData.value = await getDonationsOfCurrentUser(
    {
      page: page.value,
    },
    "model.media,latestPayment"
  );
};

const page = ref(1);

const onPage = (event: DataViewPageEvent) => {
  page.value = event.page + 1;
};

watch(page, () => {
  retrieveDonations();
});

onMounted(() => {
  retrieveDonations();
  loading.value = false;
});
</script>

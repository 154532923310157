import useApi from "@/composables/api";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import useAuthStore from "./authStore";
import { getInvitationOnMosqueOfUser } from "@/services/mosques";
import { Invitation } from "@/services/invitations";
import { Media } from "@/services/media";
import { Address } from "@/services/addresses";
import { useLocalStorage } from "@vueuse/core";

const useMosqueStore = defineStore('mosque', () => {
  const selectedMosque = ref<Mosque | null>(null);
  const selectedMosqueInvitation = useLocalStorage<Invitation | null>('selectedMosqueInvitation', null, {
    serializer: {
      read: (value) => JSON.parse(value),
      write: (value) => JSON.stringify(value)
    }
  });

  const mosques = ref<Mosque[]>([]);

  const setMosqueFromLocalStorage = () => {
    const mosque = localStorage.getItem('selectedMosque');

    if (!mosque) return null;

    selectMosque(JSON.parse(mosque) as Mosque);
  }

  const selectMosque = async (mosque: Mosque | null) => {
    selectedMosque.value = mosque;
    if (!mosque) {
      localStorage.removeItem('selectedMosque');
      return;
    }
    localStorage.setItem('selectedMosque', JSON.stringify(mosque));

    retrieveUserInvitationOnMosque(mosque.id)
  }

  const { user } = storeToRefs(useAuthStore());

  const retrieveMosquesOfUser = async () => {
    const { client } = useApi();

    if (!user) {
      throw new Error('User is not defined');
    };

    const { data } = await client.get(`/users/${user.value?.id}/mosques`, {
      params: {
        include: 'currentContributionAmount,media,address'
      }
    });

    setMosques(data)

    if (!selectedMosque.value && mosques.value.length > 0) {
      selectMosque(mosques.value[0]);
    }

    if (selectedMosque.value) {
      selectMosque(mosques.value.find(m => m.id === selectedMosque.value?.id) ?? mosques.value[0]);
    }
  }

  const setMosques = (array: Mosque[]) => {
    mosques.value = array;
  }

  const addMosque = (mosque: Mosque) => {
    mosques.value.push(mosque);

    retrieveMosquesOfUser();
  }

  const selectedMosqueContribtionAmount = computed(() => {
    return selectedMosque.value?.current_contribution_amount
  })

  const retrieveUserInvitationOnMosque = async (mosqueId: string) => {
    const { user } = useAuthStore();

    if (!user) {
      throw new Error('User is not defined');
    }

    selectedMosqueInvitation.value = await getInvitationOnMosqueOfUser(user.id, mosqueId) ?? null;
  }

  const hasRolesOnSelectedMosque = (roles: string[]) => {
    if (!selectedMosqueInvitation.value) return false;

    return roles.every(role => selectedMosqueInvitation.value?.roles.some(r => r.name === role));
  }

  return {
    selectedMosque,
    mosques,
    selectMosque,
    retrieveMosquesOfUser,
    setMosques,
    selectedMosqueContribtionAmount,
    hasRolesOnSelectedMosque,
    setMosqueFromLocalStorage,
    selectedMosqueInvitation,
    addMosque
  }
});

export interface MosqueContribtionAmount {
  id: number;
  amount: number;
  active_from: string;
  mosque_id: number;
}

export interface Mosque {
  id: string;
  name: string;
  current_contribution_amount?: Partial<MosqueContribtionAmount>;
  media?: Media[];
  address?: Address;
  private: boolean;
}

export default useMosqueStore;

<template>
  <div class="w-full h-screen px-4 pt-20 space-y-4 bg-sea-green-50 sm:px-0">
    <div class="max-w-md p-4 mx-auto bg-white rounded-md">
      <div class="flex flex-col items-center space-y-8">
        <div
          class="flex items-center justify-center w-16 h-16 rounded-full"
          :class="{
            'bg-amber-200 animate-ping': fetchedPayment?.status === 'open',
            'bg-green-500': fetchedPayment?.status === 'paid',
            'bg-red-500':
              fetchedPayment?.status !== 'paid' &&
              fetchedPayment?.status !== 'open',
          }"
        >
          <i class="text-2xl text-white pi pi-credit-card" />
        </div>
        <h2
          class="text-2xl font-bold text-center"
          :class="{
            'text-amber-500 ': fetchedPayment?.status === 'open',
            'text-green-500': fetchedPayment?.status === 'paid',
            'text-red-500':
              fetchedPayment?.status !== 'paid' &&
              fetchedPayment?.status !== 'open',
          }"
        >
          {{ paymentStatusText }}
        </h2>
        <a
          v-if="fetchedPayment?.status === 'open'"
          class="underline hover:no-underline"
          :href="currentPayment?.checkout_url"
        >
          {{ $t("actions.open-checkout") }}
        </a>
        <Button
          v-if="fetchedPayment?.status !== 'open'"
          :label="$t('actions.continue')"
          @click="navigate"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import usePaymentStore from "@/stores/paymentStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { getPayment, Payment } from "@/services/payments";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { isAxiosError } from "axios";
import { useToast } from "primevue/usetoast";
import Button from "primevue/button";
import useAuthStore from "@/stores/authStore";

const { currentPayment, continuePath } = storeToRefs(usePaymentStore());
const { setCurrentPayment, setContinuePath } = usePaymentStore();
const { push } = useRouter();

onMounted(() => {
  if (!currentPayment.value) {
    setCurrentPayment(null);
    push({ name: "Dashboard" });
  }
});

const { t } = useI18n();

const paymentStatusText = computed(() => {
  if (fetchedPayment.value?.status === "open") {
    return t("pages.payments.validate.states.processing-payment");
  }
  if (fetchedPayment.value?.status === "paid") {
    return t("pages.payments.validate.states.payment-successful");
  }
  if (fetchedPayment.value?.status === "failed") {
    return t("pages.payments.validate.states.payment-failed");
  }
  if (fetchedPayment.value?.status === "expired") {
    return t("pages.payments.validate.states.payment-expired");
  }
  if (fetchedPayment.value?.status === "canceled") {
    return t("pages.payments.validate.states.payment-canceled");
  }
  return "Processing payment";
});

const { isAuthenticated } = storeToRefs(useAuthStore());

const navigate = () => {
  if (continuePath.value) {
    push(continuePath.value);
  } else if(isAuthenticated.value) {
    push({ name: "Dashboard" });
  } else {
    push({ name: "Login" });
  }

  setCurrentPayment(null);
  setContinuePath(null);
};

const fetchedPayment = ref<Payment | null>(
  currentPayment.value ?? null
);

const fetchPayment = async () => {
  if (!currentPayment.value?.id) return;
  fetchedPayment.value = await getPayment(
    currentPayment.value.id
  );
};

const checking = ref(false);

const fetchPaymentInterval = ref();

const { add } = useToast();
const check = async () => {
  try {
    await fetchPayment();

    if (
      fetchedPayment.value?.status &&
      fetchedPayment.value?.status !== "open"
    ) {
      clearInterval(fetchPaymentInterval.value);
      checking.value = false;
    }
  } catch (error) {
    if (isAxiosError(error)) {
      clearInterval(fetchPaymentInterval.value);
      if (error.response?.status === 403) {
        add({ severity: "error", summary: error.response.data.message });
      }
      checking.value = false;
    }
  }
};
// fetch donation untile status is not open
onMounted(async () => {
  checking.value = true;
  await check();
  fetchPaymentInterval.value = setInterval(check, 2000);
});

onMounted(() => {});
</script>

import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import useMosqueStore from "./mosqueStore";
import { Address } from "@/services/addresses";
import { getUser } from "@/services/users";
import { Role } from "@/services/roles";
import useApi from "@/composables/api";
import { useLocalStorage } from "@vueuse/core";
import * as Sentry from '@sentry/vue';

const useAuthStore = defineStore('auth', () => {
  const user = ref<User | null>(null);

  const setUser = (newUser: User) => {
    user.value = newUser;

    Sentry.setUser({
      id: newUser.id,
      email: newUser.email,
      username: newUser.first_name + ' ' + newUser.last_name,
      roles: newUser.roles?.map(role => role.name),
      phonenumber: newUser.phonenumber
    });
  }

  const setUserFromServer = async () => {
    try {
      setUser(await getUser('me', 'address'));
    } catch (error) {
      isAuthenticated.value = false;
      return
    }

    isAuthenticated.value = true;

    const { retrieveMosquesOfUser, setMosqueFromLocalStorage } = useMosqueStore();
    retrieveMosquesOfUser();
    setMosqueFromLocalStorage();
  }

  const isAuthenticated = useLocalStorage<boolean>('isAuthenticated', false)

  const logout = async () => {
    const { selectMosque, setMosques } = useMosqueStore();
    const { selectedMosqueInvitation } = storeToRefs(useMosqueStore());

    selectMosque(null);
    setMosques([]);
    selectedMosqueInvitation.value = null;

    user.value = null;

    const { webClient: webClient } = useApi();

    webClient.post('/auth/logout');

    isAuthenticated.value = false;

    Sentry.setUser(null);
  }

  return {
    isAuthenticated,
    user,
    logout,
    setUser,
    setUserFromServer
  }
});

export default useAuthStore;

export interface NewAccessToken {
  plainTextToken: string;
  accessToken: AccessToken;
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: string;
  birthdate: string | Date | null;
  created_at: string;
  updated_at: string;
  roles: Array<Role>;
  address?: Address;
  phonenumber: string;
}

export interface AccessToken {
  abilities: Array<string>;
  created_at: string;
  name: string;
  tokenable: User;
}

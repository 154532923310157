import { createRouter, createWebHistory } from "vue-router";
import Index from "@/pages/Index.vue";
import useAuthStore from "@/stores/authStore";
import Dashboard from "@/pages/Dashboard/Index.vue";
import invitationsRoutes from "@/router/invitations";
import authRoutes from "@/router/auth";
import donationsRoutes from "@/router/donations";
import profileRoutes from "@/router/profile";
import paymentsRoutes from "@/router/payments";
import onboardingRoutes from "@/router/onboarding";
import mosquesRoutes from "@/router/mosques";
import useMosqueStore from "@/stores/mosqueStore";
import userRoutes from "@/router/user";
import ServiceUnavailable from "@/pages/ServiceUnavailable.vue";
import NotFound from "@/pages/NotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Index",
      component: Index,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "/",
          name: "Dashboard",
          component: Dashboard
        },
        ...invitationsRoutes,
        ...mosquesRoutes,
        ...profileRoutes,
        ...mosquesRoutes,
        ...userRoutes
      ]
    },
    ...authRoutes,
    ...donationsRoutes,
    ...paymentsRoutes,
    ...onboardingRoutes,
    {
      path: "/unavailable",
      name: "ServiceUnavailable",
      component: ServiceUnavailable
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound
    }

  ],
});

router.beforeEach((to, _from, next) => {
  const { isAuthenticated } = useAuthStore();

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login', query: { redirect: to.fullPath } });
    return;
  }

  next();
})

router.beforeEach((to, _from, next) => {
  const { selectedMosque, hasRolesOnSelectedMosque } = useMosqueStore();

  if (!to.meta.selectedMosqueRoles) {
    return next();
  }

  if (!selectedMosque) {
    return next();
  }

  if (selectedMosque && hasRolesOnSelectedMosque(to.meta.selectedMosqueRoles as string[])
  ) {
    return next();
  }

  return next(_from || { name: 'NotFound' });
})

export default router;

<template>
  <div
    class="p-2 border rounded-md"
    :class="{
      'text-sea-green-500 border-sea-green-500': selected,
      'cursor-not-allowed bg-sea-green-500 text-white': disabled,
      'cursor-pointer': !disabled,
    }"
  >
    <p>{{ getMonthName(month) }}</p>
  </div>
</template>

<script lang="ts" setup>
const getMonthName = (month: number) => {
  return new Date(2021, month - 1, 1).toLocaleString('default', { month: 'long' });
}

defineProps<{
  month: number;
  selected: boolean;
  disabled?: boolean;
}>();
</script>

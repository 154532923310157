<template>
  <Dialog
    v-model:visible="model"
    modal
    :draggable="false"
  >
    <template #header>
      <h2 class="font-bold white-space-nowrap">
        {{ $t('components.modals.createInvitations.title') }}
      </h2>
    </template>
    <form
      class="flex flex-col w-full gap-2"
      @submit.prevent="invite"
    >
      <InputWrapper
        :label="$t('components.modals.createInvitations.emailAddresses')"
        :errors="getErrors('emails')"
      >
        <Chips
          v-model="emails"
          add-on-blur
          :placeholder="$t('components.modals.createInvitations.emailAddresses')"
          :pt="{
            container: 'w-full gap-2'
          }"
          :allow-duplicate="false"
          @add="onAdd"
        />
      </InputWrapper>
      <InputWrapper
        :label="t('components.modals.createInvitations.role')"
        :errors="getErrors('roles.0')"
      >
        <Dropdown
          key="key"
          v-model="selectedRole"
          :options="Role.objects"
          option-label="label"
          option-value="value"
          :placeholder="t('components.modals.createInvitations.role')"
        />
      </InputWrapper>
    </form>
    <template #footer>
      <Button
        outlined
        severity="secondary"
        :label="t('actions.cancel')"
        @click="closeModal"
      />
      <Button
        :label="t('components.modals.createInvitations.invite', emails.length)"
        :loading="loading"
        @click="invite"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Chips, { ChipsAddEvent } from 'primevue/chips';
import InputWrapper from '../form/InputWrapper.vue';
import { getAvailableRolesOnModelType } from "@/services/invitations";
import { ref } from 'vue';
import { watch } from 'vue';
import Dropdown from 'primevue/dropdown';
import Role from '@/enums/roles';
import { createInvitationsOnMosque } from '@/services/invitations';
import useMosqueStore from '@/stores/mosqueStore';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { isAxiosError } from 'axios';
import useError from '@/composables/error';

const emails = ref<string[]>([]);
const selectedRole = ref<string>('');


const { getErrors, setErrorBag, addError, clearErrors } = useError();

const { selectedMosque } = useMosqueStore();
const {add} = useToast();
const {t} = useI18n();

const loading = ref(false);

const invite = async () => {
  loading.value = true;
  try {
    if(!selectedMosque) return;
    const invitations = await createInvitationsOnMosque(selectedMosque.id, emails.value, [selectedRole.value]); 

    clearErrors();

    add({
      severity: 'success',
      detail: t('components.modals.createInvitations.invitationsHaveBeenCreated', invitations.length)
    })

    closeModal();
    loading.value = false;
  } catch (e) {
    if(isAxiosError(e)) {
      setErrorBag(e.response?.data);
    }
    loading.value = false;
  }
}

const onAdd = (event: ChipsAddEvent) => {
    const input = event.originalEvent.target as HTMLInputElement;

    clearErrors('emails');
    

    if (!/^\S+@\S+\.\S+$/.test(input.value)) {
        emails.value.pop();
        
        addError('emails', 'Please enter a valid email address');
        return;
    }
}

const closeModal = () => {
  model.value = false;
}

const availableRoles = ref<string[]>([]);

const fetchAvailableRoles = async () => {
    availableRoles.value = await getAvailableRolesOnModelType('mosques')
}

const model = defineModel({
    required: true,
    type: Boolean,
})

watch(model, (value) => {
    if (value) fetchAvailableRoles();
    else {
        emails.value = [];
        selectedRole.value = '';
        clearErrors();
    }
})
</script>
<template>
  <div class="grid grid-flow-row-dense grid-cols-4 gap-2 pt-20">
    <Card class="col-span-full">
      <template #title>
        {{ user?.first_name }} {{ user?.last_name }}
      </template>
      <template
        v-if="user?.address"
        #subtitle
      >
        <div class="flex">
          <Tag
            v-for="(role, index) in invitation?.roles"
            :key="index"
          >
            {{ Role.getTranslatedValue(role.name) }}
          </Tag>
        </div>
      </template>
      <template #content>
        <TabView>
          <TabPanel header="Info">
            <ul class="list-disc">
              <li v-if="user?.birthdate">
                {{ $t("pages.mosques.users.show.bornOn") }}
                <b>{{ format(user?.birthdate, "d MMM yyyy") }}</b>
              </li>
              <li v-if="invitation?.accepted_at">
                {{ $t("pages.mosques.users.index.memberSince") }}
                <b>{{ format(invitation?.accepted_at, "d MMM yyyy") }}</b>
              </li>
              <li v-if="selectedMosque?.address && user?.address">
                <b>{{
                  round(
                    distanceBetween(selectedMosque?.address, user?.address) /
                      1000,
                    2
                  )
                }}km</b>
                {{ $t("pages.mosques.users.show.awayFromTheMosque") }}
              </li>
            </ul>
          </TabPanel>
          <TabPanel header="Contact">
            <ul class="">
              <li v-if="user?.email">
                <a
                  class="flex items-center"
                  :href="`mailto:${user?.email}`"
                ><i class="mr-2 pi pi-envelope" />{{ user?.email }}</a>
              </li>
              <li v-if="user?.phonenumber">
                <a
                  class="flex items-center"
                  :href="`tel:${user?.email}`"
                ><i class="mr-2 pi pi-phone" />{{ user?.phonenumber }}</a>
              </li>
            </ul>
          </TabPanel>
        </TabView>
      </template>
      <template #footer>
        <div class="flex gap-3 mt-1">
          <a :href="`mailto:${user?.email}`">
            <Button
              icon="pi pi-envelope"
              label="Send mail"
            />
          </a>
          <a
            v-if="user?.phonenumber"
            :href="`tel:${user?.phonenumber}`"
          >
            <Button
              outlined
              icon="pi pi-phone"
              label="Call"
            />
          </a>
        </div>
      </template>
    </Card>

    <DonationsCard
      v-if="user"
      :user="user"
    />

    <ContributionsCard
      v-if="user && selectedMosque"
      class="sm:col-span-2 col-span-full"
      :user="user"
      :mosque="selectedMosque"
    />
  </div>
</template>

<script lang="ts" setup>
import { Invitation } from "@/services/invitations";
import { User } from "@/stores/authStore";
import useMosqueStore from "@/stores/mosqueStore";
import { format } from "date-fns";
import { storeToRefs } from "pinia";
import Button from "primevue/button";
import Card from "primevue/card";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { distanceBetween } from "@/services/addresses";
import { round } from "lodash";
import Role from "@/enums/roles";
import Tag from "primevue/tag";
import DonationsCard from "@/components/Mosques/Users/DonationsCard.vue";
import { getInvitationOnMosqueOfUser, getUserOfMosque } from '@/services/mosques';
import ContributionsCard from "@/components/Mosques/Users/ContributionsCard.vue";

const user = ref<User>();

const { params } = useRoute();
const { selectedMosque } = storeToRefs(useMosqueStore());
const userId = params.userId.toString();
const fetchUser = async () => {
  if (!selectedMosque.value) return;
  user.value = await getUserOfMosque(
    selectedMosque.value?.id,
    userId,
    "address"
  );
};

const invitation = ref<Invitation>();
const fetchInvitation = async () => {
  if (!selectedMosque.value) return;
  invitation.value = await getInvitationOnMosqueOfUser(
    userId,
    selectedMosque.value?.id
  );
};

onMounted(fetchUser);
onMounted(fetchInvitation);
</script>

import useApi, { PaginatedResponse, PaginationOptions, QueryOptions } from "@/composables/api";
import { Invitation } from "./invitations";
import { Mosque } from "@/stores/mosqueStore";
import { User } from "@/stores/authStore";

const getInvitationOnMosqueOfUser = async (userId: number | string, mosqueId: string): Promise<Invitation> => {
  const { client } = useApi();

  const { data } = await client.get(`/mosques/${mosqueId}/users/${userId}/accepted-invitation`, {
    params: {
      include: 'roles.permissions,permissions',
    }
  });

  return data;
}

const getMosques = async (pagination?: PaginationOptions, include?: string, filter?: Record<string, string | undefined>): Promise<PaginatedResponse<Mosque>> => {
  const { client } = useApi();

  const { data } = await client.get('/mosques', {
    params: {
      include,
      filter,
      ...pagination
    },
  });

  return data;
}

const getMosque = (id: string | number, queryOptions: QueryOptions) => {
  const { fetch } = useApi();

  return fetch<Mosque>(`/mosques/${id}`, {
    params: queryOptions
  });
}

const updateMosque = async (id: string | number, mosqueData: Record<string, unknown>): Promise<Mosque> => {
  const { client } = useApi();

  const { data } = await client.patch(`/mosques/${id}`, mosqueData);

  return data;
}

const joinMosque = async (mosqueId: string, roles: Array<string>): Promise<Invitation> => {
  const { client } = useApi();

  const { data } = await client.post(`/mosques/${mosqueId}/join`, {
    roles
  });

  return data;
}

const getUsersOfMosque = async (mosqueId: string, pagination?: PaginationOptions, include?: string, filter?: Record<string, string | null>): Promise<PaginatedResponse<Mosque>> => {
  const { client } = useApi();

  const { data } = await client.get(`/mosques/${mosqueId}/users`, {
    params: {
      include,
      filter,
      ...pagination
    },
  });

  return data;
}

const getUserOfMosque = async (mosqueId: string, userId: number | string, include?: string): Promise<User> => {
  const { client } = useApi();

  const { data } = await client.get(`/mosques/${mosqueId}/users/${userId}`, {
    params: {
      include,
    },
  });

  return data;
}

const getInvitationsOfMosque = async (mosqueId: string, pagination?: PaginationOptions, include?: string, filter?: Record<string, string | null>): Promise<PaginatedResponse<Invitation>> => {
  const { client } = useApi();

  const { data } = await client.get(`/mosques/${mosqueId}/invitations`, {
    params: {
      include,
      filter,
      ...pagination
    },
  });

  return data;
}

export {
  getMosque,
  getInvitationOnMosqueOfUser,
  getInvitationsOfMosque,
  getMosques,
  updateMosque,
  joinMosque,
  getUsersOfMosque,
  getUserOfMosque
}


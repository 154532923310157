<template>
  <img
    v-if="!$slots.default"
    :src="image"
    @click="open()"
  >
  <slot
    v-else
    :image="image"
    @open="open()"
  />
</template>

<script lang="ts" setup>
import { useFileDialog } from '@vueuse/core';
import useApi from '@/composables/api';
import { ref } from 'vue';
import { isAxiosError } from 'axios';
import { useToast } from 'primevue/usetoast';

const props = withDefaults(defineProps<{
  image: string;
  path: string;
  method: 'POST' | 'PATCH';
  name: string;
  accept?: string;
}>(), {
  accept: 'image/*'
});
const emit = defineEmits<{
  upload: [FileList]
}>();

const { open, onChange } = useFileDialog({
  multiple: false,
  accept: props.accept,
});

const loading = ref(false);
const { add } = useToast();


onChange(async (files: FileList | null) => {
  if (!files) {
    return;
  };

  loading.value = true;

  const form = new FormData();

  form.append(props.name, files[0]);

  form.append('_method', props.method);

  const { client } = useApi()

  try {
    await client.request({
      url: props.path,
      method: "POST",
      data: form,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    emit('upload', files);
  } catch (e) {
    if(isAxiosError(e)) {
      add({
        severity: 'error',
        detail: e.response?.data.message || e.message
      });
    } else {
      throw e;
    }
  }

  loading.value = false;
});
</script>

import { RouteRecordRaw } from "vue-router";
import Invitations from "@/pages/Invitations/Index.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/invitations",
        name: "Invitations",
        component: Invitations
    }
];

export default routes;
<template>
  <div
    v-if="loading"
    class="flex justify-center"
  >
    <ProgressSpinner style="width: 50px; height: 50px" />
  </div>
  <div
    v-else
    class="flex items-center justify-between"
  >
    <i
      class="text-xl cursor-pointer pi pi-angle-left hover:text-gray-600"
      @click="scrollHorizontal(-100)"
    />
    <div
      ref="itemsContainer"
      class="flex justify-between space-x-8 overflow-x-auto scrollbar-hide"
    >
      <ContributionsOverviewItem
        v-for="item in contributionsOverview"
        :key="item.date"
        :item="item"
        @click="onItemClick(item)"
      />
    </div>
    <i
      class="text-xl cursor-pointer pi pi-angle-right hover:text-gray-600"
      @click="scrollHorizontal(100)"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import ProgressSpinner from 'primevue/progressspinner';
import { watch } from "vue";
import { ContributionsOverviewItem as Item } from "@/services/contributions";
import { onMounted } from "vue";
import ContributionsOverviewItem from "@/components/Contributions/ContributionOverview/ContributionOverviewItem.vue";

const { contributionsOverview, loading } = defineProps<{
  loading: boolean;
  contributionsOverview: Array<Item>;
}>();

const emit = defineEmits<{
  (event: 'itemClick', item: Item): void;
}>();


const onItemClick = (item: Item) => {
  emit('itemClick', item);
}

onMounted(async () => {
  scrollToEnd();
});

watch(contributionsOverview, async () => {
  scrollToEnd();
});

const itemsContainer = ref<HTMLElement | null>(null);

const scrollToEnd = () => {
  if(!itemsContainer.value) return;
  itemsContainer.value.scrollLeft = itemsContainer.value.scrollWidth;
}

const scrollHorizontal = (amount: number) => {
  if(!itemsContainer.value) return;
  itemsContainer.value.scrollBy({
    left: amount,
    behavior: "smooth",
  });
}
</script>

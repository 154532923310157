import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import './style.css'
import 'primevue/resources/themes/lara-light-green/theme.css'
import { createPinia } from 'pinia'
import router from './router';
import ToastService from 'primevue/toastservice';
import i18n from '@/vendor/i18n';
import sentry from '@/plugins/sentry';
import Tooltip from 'primevue/tooltip';
import DialogService from 'primevue/dialogservice';
import BadgeDirective from 'primevue/badgedirective';

createApp(App)
  .directive('tooltip', Tooltip)
  .use(sentry)
  .use(PrimeVue, {
    cssLayerOrder: 'tailwind-base, primevue, tailwind-utilities'
  })
  .use(ToastService)
  .directive('badge', BadgeDirective)
  .use(DialogService)
  .use(i18n)
  .use(createPinia())
  .use(router)
  .mount('#app')

<template>
  <Card class="flex flex-col">
    <template #title>
      <h2>{{ $t('contributions') }}</h2>
    </template>
    <template #content>
      <ContributionsOverview
        :loading="overviewLoading"
        :contributions-overview="overviewData || []"
      />
      <Divider />
      <DataView
        :value="contributionsData?.data"
        data-key="id"
        :loading="contributionsLoading"
        :pt="{
          content: 'space-y-2 bg-transparent mb-2',
        }"
        lazy
        paginator
        :rows="contributionsData?.per_page"
        :total-records="contributionsData?.total"
        :always-show-paginator="false"
        @page="onPage"
      >
        <template #list="slotProps">
          <Contribution
            v-for="contribution in slotProps.items"
            :key="contribution.id"
            :contribution="contribution"
          />
        </template>
        <template #empty>
          <div class="flex flex-col items-center justify-center h-96">
            <p class="text-lg font-semibold">
              {{ $t("pages.users.contributions.index.no-contributions") }}
            </p>
          </div>
        </template>
      </DataView>
    </template>
    <template #footer>
      <Button
        :label="$t('actions.add-contribution')"
        @click="addContribution"
      />
    </template>
  </Card>
</template>

<script lang="ts" setup>
import ContributionsOverview from "@/components/Contributions/ContributionOverview/ContributionsOverview.vue";
import Contribution from "@/components/Contributions/Contribution.vue";
import { Contribution as ContributionInterface, ContributionsOverviewItem } from "@/services/contributions";
import Button from "primevue/button";
import Card from "primevue/card";
import Divider from "primevue/divider";
import useApi, { PaginatedResponse } from "@/composables/api";
import { ref } from "vue";
import { format, subMonths } from "date-fns";
import DataView, { DataViewPageEvent } from "primevue/dataview";
import useDialog from '@/composables/useDialog';
import AddContribution, { ModalData } from "@/components/Modals/AddContribution.vue";
import { useI18n } from "vue-i18n";
import { User } from "@/stores/authStore";
import { Mosque } from "@/stores/mosqueStore";

const { fetch } =  useApi()

const { user, mosque } = defineProps<{
    user: User;
    mosque: Mosque;
}>();

const userId = user.id;
const mosqueId = mosque.id;

const dateRange = ref({
    start_date: format(subMonths(new Date(), 11), 'yyyy-MM'),
    end_date: format(new Date(), 'yyyy-MM'),
});

const { data: overviewData, loading: overviewLoading, refetch: refetchOverview } = fetch<ContributionsOverviewItem[]>(`/mosques/${mosqueId}/users/${userId}/contributions/overview`, {
  params: {
    start_date: dateRange.value.start_date,
    end_date: dateRange.value.end_date,
    include: 'payments'
  }
})

const { data: contributionsData, loading: contributionsLoading, refetch : refetchContributions} = fetch<PaginatedResponse<ContributionInterface>>(`/mosques/${mosqueId}/users/${userId}/contributions`, {
  params: {
    include: 'paidPayment',
    filter: {
      paid: true,
    },
    per_page: 5,
    page: 1,
  }
})

const onPage = (event: DataViewPageEvent) => {
  refetchContributions({
    params: {
      page: event.page + 1,
    }
  })
}



const { open } = useDialog()
const { t } = useI18n()

const addContribution = () => {
  open<ModalData>(AddContribution, {
    props: {
      header: t('components.modals.updateMandateModal.title'),
    },
    data: {
      user,
      mosque: mosque,
    },
    onClose: (success) => {
      if(success) {
        refetchContributions()
        refetchOverview()
      }
    }
  })
}
</script>

import { RouteRecordRaw } from "vue-router";
import Auth from "@/pages/Auth/Index.vue";
import Login from "@/pages/Auth/Login.vue";
import Register from "@/pages/Auth/Register.vue";
import ForgotPassword from "@/pages/Auth/ForgotPassword.vue";
import ResetPassword from "@/pages/Auth/ResetPassword.vue";

const routes: RouteRecordRaw[] = [

  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login
      },
      {
        path: "register",
        name: "Register",
        component: Register
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword
      },
    ],
  }
];

export default routes;

<template>
  <Card>
    <template #title>
      <h2>{{ $t("components.profile.addressCard.title") }}</h2>
    </template>
    <template #content>
      <form
        class="grid grid-cols-6 gap-4"
        @submit.prevent="save"
      >
        <TextInput
          v-model="address.street_name"
          class="col-span-4"
          :label="$t('addresses.street')"
          :placeholder="$t('addresses.street')"
          :errors="error.errors?.['address.street_name']"
          required
        />
        <TextInput
          v-model="address.house_number"
          class="col-span-2"
          :label="$t('addresses.houseNumber')"
          :placeholder="$t('addresses.houseNumber')"
          :errors="error.errors?.['address.house_number']"
          required
        />
        <TextInput
          v-model="address.city"
          class="col-span-full"
          :label="$t('addresses.city')"
          :placeholder="$t('addresses.city')"
          :errors="error.errors?.['address.city']"
          required
        />

        <InputWrapper
          class="col-span-3"
          :label="$t('addresses.zipcode')"
          :errors="error.errors?.['address.zipcode']"
        >
          <InputMask
            v-model="address.postal_code"
            :placeholder="$t('addresses.zipcode')"
            mask="9999aa"
            required
          />
        </InputWrapper>
        <InputWrapper
          class="col-span-3"
          :label="$t('addresses.country')"
          :errors="error.errors?.['address.country_code']"
        >
          <Dropdown
            v-model="countryCode"
            :options="availableCountryCodes"
            option-label="name"
            :placeholder="$t('components.profile.addressCard.selectCountry')"
            class="w-full md:w-14rem"
          />
        </InputWrapper>
      </form>
    </template>
    <template #footer>
      <Button
        :loading="loading"
        :label="$t('actions.save')"
        :disabled="!changed"
        type="submit"
        class="w-full"
        @click="save"
      />
    </template>
  </Card>
</template>

<script lang="ts" setup>
import Card from "primevue/card";
import TextInput from "@/components/form/TextInput.vue";
import Button from "primevue/button";
import { Address } from "@/services/addresses";
import InputWrapper from "../form/InputWrapper.vue";
import Dropdown from "primevue/dropdown";
import { useI18n } from "vue-i18n";
import InputMask from "primevue/inputmask";
import { ref, watch } from "vue";
import { AxiosError } from "axios";
import { useToast } from "primevue/usetoast";
import { ErrorBag } from "@/composables/api";
import { computed } from "vue";
import { isEqual, clone } from "lodash";
import { PropType } from "vue";

const { add } = useToast();

const error = ref<ErrorBag>({});

const emit = defineEmits(["save"]);

const props = defineProps({
  address: {
    type: Object as PropType<Address>,
    required: false,
    default: () => ({} as Address),
  },
})

watch(() => props.address, (newAddress) => {
  address.value = clone(newAddress ?? {} as Address);
});

const address = ref<Address>(clone(props.address) || ({} as Address));

const save = async () => {
  loading.value = true;
  emit("save", address.value, onSuccess, onError);
};

const onError = (e: AxiosError) => {
  loading.value = false;
  error.value = e.response?.data as ErrorBag;
  add({
    severity: "error",
    summary: "Error",
    detail: error.value.message || "An unknown error occurred",
  });
};

const onSuccess = () => {
  loading.value = false;
  add({
    severity: "success",
    detail: t("components.profile.addressCard.updated"),
  });
};

const { t } = useI18n();
const availableCountryCodes = [{ name: t("countries.nl"), code: "NL" }];

const countryCode = computed({
  get(): { name: string; code: string } {
    return (
      availableCountryCodes.find(
        (c) => c.code === address.value.country_code
      ) || { code: "", name: "" }
    );
  },
  set(value: { name: string; code: string }) {
    address.value.country_code = value.code as "NL";
  },
});

const loading = ref(false);

const changed = computed((): boolean => {
  return !isEqual(address.value, props.address);
});
</script>

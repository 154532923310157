import useApi, { PaginatedResponse, PaginationOptions, QueryOptions } from "@/composables/api";
import { Payment, PaymentStatus, PaymentType } from "./payments";
import { format } from "date-fns";
import { UTCDate } from "@date-fns/utc";
import { pickBy } from "lodash";
import { Mosque } from "@/stores/mosqueStore";

const getDonation = async (donationId: string | number): Promise<Donation> => {
  const { client } = useApi();

  const { data } = await client.get(`/donations/${donationId}`);

  return data;
}

const getDonationsOfCurrentUser = async (pagination?: PaginationOptions, include?: string, filter?: Record<string, string>): Promise<PaginatedResponse<Donation>> => {
  const { client } = useApi();

  const { data } = await client.get(`/users/donations`, {
    params: {
      include,
      filter,
      ...pagination
    }
  });

  return data;
}

const createDonation = async (mosqueId: string | number, form: {
  amount: number,
  note?: string,
  redirect_url: string,
  anonymous: boolean
}): Promise<Donation> => {
  const { client } = useApi();

  const { data } = await client.post(`/mosques/${mosqueId}/donations`, form);

  return data;
}

const createDonationOnMosque = async (options: {
  mosque_id: string | number,
  user_id: string | number,
  amount: number,
  note?: string,
  payment_type: PaymentType,
  anonymous: boolean,
  redirect_url: string,
}): Promise<Donation> => {
  const { client } = useApi();

  const { data } = await client.post(`/donations`, options);

  return data;
}

const getDonationStatsOfMosque = async (mosqueId: string | number, from: Date, to: Date): Promise<{ total_amount: number, total_donations: number }> => {
  const { client } = useApi();

  const { data } = await client.get(`/mosques/${mosqueId}/donations/stats`, {
    params: {
      from: format(new UTCDate(from), "yyyy-MM-dd HH:mm:ss"),
      to: format(new UTCDate(to), "yyyy-MM-dd HH:mm:ss")
    }
  });

  return data;
}

const getDonationStatsOfUserOnMosque = async (mosqueId: string | number, userId: string | number, from?: Date, to?: Date): Promise<{ total_amount: number, total_donations: number }> => {
  const { client } = useApi();

  const { data } = await client.get(`/mosques/${mosqueId}/users/${userId}/donations/stats`, {
    params: pickBy({
      from: from ? format(new UTCDate(from), "yyyy-MM-dd HH:mm:ss") : undefined,
      to: to ? format(new UTCDate(to), "yyyy-MM-dd HH:mm:ss") : undefined
    })
  });

  return data;
}

const getDonationsOfMosqueUser = async (mosqueId: string, userId: string, queryOptions: QueryOptions, paginationOtions: PaginationOptions): Promise<PaginatedResponse<Donation>> => {
  const { client } = useApi();

  const { data } = await client.get(`/mosques/${mosqueId}/users/${userId}/donations`, {
    params: {
      ...queryOptions,
      ...paginationOtions
    }
  });

  return data;
}

const getStatusSeverity = (status: string) => {
  switch (status) {
    case "open":
      return "warning";
    case "paid":
      return "success";
    case "canceled":
      return "danger";
    case "expired":
      return "danger";
    case "rejected":
      return "danger";
    default:
      return "info";
  }
};

export {
  getDonation,
  createDonation,
  getDonationsOfCurrentUser,
  getDonationStatsOfMosque,
  getStatusSeverity,
  getDonationsOfMosqueUser,
  getDonationStatsOfUserOnMosque,
  createDonationOnMosque
}
export interface Donation {
  id: number;
  amount: number;
  mosque_id: number;
  user_id?: number | null;
  open_payment?: Payment;
  note: string;
  anonymous: boolean;
  latest_payment?: Payment;
  payment_status?: PaymentStatus;
  model?: Mosque;
  created_at: string;
  updated_at: string;
}

export interface DonationStats {
  total_amount: number;
  total_donations: number;
}

<template>
  <Card
    class="col-span-2 row-span-2"
    :pt="{
      body: 'flex flex-col justify-between h-full',
    }"
  >
    <template #title>
      {{ $t("components.donations.donation-card.title") }}
    </template>
    <template #subtitle>
      {{ $t("components.donations.donation-card.subtitle") }}
    </template>
    <template #content>
      <div class="flex flex-col items-center">
        <div class="flex justify-center gap-4">
          <div class="flex flex-col items-center">
            <p class="text-4xl font-semibold text-center">
              {{ donationStatsToday?.total_donations }}
            </p>
            <p class="text-sm text-center">
              {{ $t("components.donations.donation-card.donations-count") }}
            </p>
          </div>
          <div class="flex flex-col items-center">
            <p class="text-4xl font-semibold text-center">
              {{ formatAmount(donationStatsToday?.total_amount / 100) }}
            </p>
            <p class="text-sm text-center">
              {{ $t("components.donations.donation-card.total-amount") }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <RouterLink
        v-if="selectedMosque"
        :to="`/donations/create/${selectedMosque.id}`"
      >
        <Button
          class="w-full"
          :label="$t('actions.donate')"
        />
      </RouterLink>
    </template>
  </Card>
</template>

<script lang="ts" setup>
import Card from "primevue/card";
import Button from "primevue/button";
import { storeToRefs } from "pinia";
import useMosqueStore from "@/stores/mosqueStore";
import { getDonationStatsOfMosque} from "@/services/donations";
import { onMounted } from "vue";
import { ref } from "vue";
import { startOfDay, endOfDay } from "date-fns";
import { watch } from "vue";
import {formatAmount} from "@/services/payments"

const { selectedMosque } = storeToRefs(useMosqueStore());

const donationStatsToday = ref<{ total_amount: number, total_donations: number }>({
  total_amount: 0,
  total_donations: 0,
});

const retrieveStats = async () => {
  if (!selectedMosque.value) return;
  donationStatsToday.value = await getDonationStatsOfMosque(selectedMosque.value.id, startOfDay(new Date), endOfDay(new Date));
}

onMounted(retrieveStats);

watch(selectedMosque, retrieveStats);


defineProps<{
  todayDonationsCount: number;
}>();
</script>

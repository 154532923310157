import { Payment } from "@/services/payments";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core"

const usePaymentStore = defineStore('payment', () => {
  const currentPayment = useLocalStorage<Payment | null>('currentPayment', null, {
    serializer: {
      read: (value) => JSON.parse(value),
      write: (value) => JSON.stringify(value)
    }
  });

  const continuePath = useLocalStorage<string | null>('continuePath', null);

  const setCurrentPayment = (payment: Payment | null) => {
    currentPayment.value = payment;
  }

  const setContinuePath = (path: string | null) => {
    continuePath.value = path;
  }

  return {
    currentPayment,
    setCurrentPayment,
    continuePath,
    setContinuePath

  }
});
export default usePaymentStore;

<template>
  <h1 class="text-4xl font-bold text-center text-sea-green-500">
    {{ $t("pages.auth.register.greeting") }}
  </h1>
  <Form
    class="grid w-full max-w-md grid-cols-2 gap-2"
    :error="error"
    :form="form"
    @submit.prevent="submit"
  >
    <TextInput
      v-model="form.first_name"
      type="text"
      :label="$t('firstName')"
      :placeholder="$t('firstName')"
      :errors="error.getErrors('first_name')"
      required
    />
    <TextInput
      v-model="form.last_name"
      type="text"
      :label="$t('lastname')"
      :placeholder="$t('lastname')"
      :errors="error.getErrors('last_name')"
      required
    />
    <TextInput
      v-model="form.email"
      class="col-span-2"
      type="email"
      :label="$t('email')"
      :placeholder="$t('pages.auth.register.form.email.placeholder')"
      :errors="error.getErrors('email')"
      required
    />
    <PasswordInput
      v-model="form.password"
      class="col-span-2"
      :label="$t('password')"
      :placeholder="$t('pages.auth.register.form.password.placeholder')"
      toggle-mask
      :errors="error.getErrors('password')"
      required
      autocomplete="new-password"
    />
    <Button
      class="col-span-2"
      :loading="loading"
      type="submit"
      :label="$t('actions.register')"
    />
    <RouterLink
      to="/auth/login"
      class="col-span-2 text-sm text-sea-green-400"
    >
      <Button
        type="submit"
        :label="$t('pages.auth.register.goToLogin')"
        class="w-full"
        link
      />
    </RouterLink>
  </Form>
</template>

<script lang="ts" setup>
import Button from "primevue/button";
import { NewAccessToken } from "@/stores/authStore";
import { watch } from "vue";
import TextInput from "@/components/form/TextInput.vue";
import PasswordInput from "@/components/form/PasswordInput.vue";
import { useRoute, useRouter } from "vue-router";
import useForm from "@/composables/form";
import useApi from "@/composables/api";
import Form from "@/components/form/Form.vue";

const { client } = useApi();

const { form, loading, submit, data , error } = useForm<{
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}, NewAccessToken>({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
}, async (form) => {
  return (await client.post("/auth/register", form)).data;
});

const { push } = useRouter();
const { query } = useRoute();

watch(data, () => {
  if (data.value) {
    const redirect = query.redirect?.toString()
      ? query.redirect.toString()
      : "/auth/login";

    push(redirect);
  }
});
</script>

const getMediaUrl = (mediaCollection: Array<Media> = [], collectionName: string, conversion?: string | number, defaultUrl: string = ''): string => {

    const media = mediaCollection.find(m => m.collection_name === collectionName)

    if (conversion) {
        return media?.conversions[conversion.toString()] ?? defaultUrl
    }

    return media?.url ?? defaultUrl
}

export {
    getMediaUrl
}

export interface Media {
    id: number;
    collection_name: string;
    file_name: string;
    mime_type: string;
    size: number;
    url: string;
    conversions: Record<string, string>;
}
<template>
  <Card>
    <template #title>
      <h2>{{ $t("components.profile.MyDetails.title") }}</h2>
    </template>
    <template #content>
      <form
        class="grid grid-cols-2 gap-4"
        @submit.prevent="save"
      >
        <TextInput
          v-model="data.first_name"
          :label="$t('firstName')"
          :placeholder="$t('firstName')"
          :errors="error.errors?.first_name"
          required
        />
        <TextInput
          v-model="data.last_name"
          :label="$t('lastname')"
          :placeholder="$t('lastname')"
          :errors="error.errors?.last_name"
          required
        />
        <InputWrapper
          class="col-span-full"
          :label="$t('users.birthdate')"
          :errors="error.errors?.['birthdate']"
        >
          <Calendar
            v-model="data.birthdate"
            :placeholder="$t('users.birthdate')"
            date-format="dd-mm-yy"
            :max-date="new Date()"
            required
            show-icon
          />
        </InputWrapper>
        <InputWrapper
          class="col-span-full"
          :label="$t('users.phonenumber')"
          :errors="error.errors?.['phonenumber']"
        >
          <InputMask
            v-model="data.phonenumber"
            mask="0699999999"
            placeholder="0612345678"
          />
        </InputWrapper>
        <TextInput
          v-model="data.email"
          type="email"
          class="col-span-full"
          :label="$t('email')"
          :placeholder="$t('pages.auth.login.form.email.placeholder')"
          disabled
        />
      </form>
    </template>
    <template #footer>
      <Button
        :label="$t('actions.save')"
        type="submit"
        class="w-full"
        :disabled="!changed"
        :loading="loading"
        @click="save"
      />
    </template>
  </Card>
</template>

<script lang="ts" setup>
import Card from "primevue/card";
import TextInput from "@/components/form/TextInput.vue";
import Button from "primevue/button";
import useAuthStore, { User } from "@/stores/authStore";
import { clone, isEqual } from "lodash";
import { ref } from "vue";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { updateUser } from "@/services/users";
import { AxiosError, isAxiosError } from "axios";
import { ErrorBag } from "@/composables/api";
import { useToast } from "primevue/usetoast";
import Calendar from "primevue/calendar";
import InputWrapper from "../form/InputWrapper.vue";
import { watch } from "vue";
import { format } from "date-fns";
import InputMask from "primevue/inputmask";
const { user } = storeToRefs(useAuthStore());

const data = ref();

watch(user, () => {
  data.value = clone(user.value) as User;
  data.value.birthdate = data.value.birthdate ? new Date(data.value.birthdate) : null;
}, {
  immediate: true,
});

const loading = ref(false);

const save = async () => {
  try {
    loading.value = true;
    if (!user.value) return;
    const newUser = await updateUser(
      user.value?.id,
      {
        first_name: data.value.first_name,
        last_name: data.value.last_name,
        birthdate: data.value.birthdate ? format(data.value.birthdate, "yyyy-MM-dd") : null,
        phonenumber: data.value.phonenumber,
      }
    );

    user.value.first_name = newUser.first_name;
    user.value.last_name = newUser.last_name;
    user.value.birthdate = newUser.birthdate;
    user.value.phonenumber = newUser.phonenumber;

    loading.value = false;
  } catch (e) {
    if (isAxiosError(e)) onError(e);
    loading.value = false;
  }
};

const error = ref<ErrorBag>({});
const { add } = useToast();

const onError = (e: AxiosError) => {
  error.value = e.response?.data as ErrorBag;
  add({
    severity: "error",
    detail: error.value.message,
  });
};

const changed = computed((): boolean => {
  return !isEqual(
    {
      first_name: user.value?.first_name,
      last_name: user.value?.last_name,
      birthdate: user.value?.birthdate,
      phonenumber: user.value?.phonenumber,
    },
    {
      first_name: data.value.first_name,
      last_name: data.value.last_name,
      birthdate: data.value.birthdate ? format(data.value.birthdate, "yyyy-MM-dd") : null,
      phonenumber: data.value.phonenumber,
    }
  );
});
</script>
